import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import { errorToast } from "../utils";
import {
  ERROR_MESSAGE,
  LOOKUP_DELETE_MESSAGE,
  LOOKUP_UPDATE_MESSAGE,
  LOOKUP_CREATE_MESSAGE,
} from "../constants";
import {
  GET_LOOKUPS,
  CREATE_LOOKUPS,
  UPDATE_LOOKUPS,
  DELETE_LOOKUPS,
  GET_SINGLE_LOOKUPS,
  callRequest,
} from "../services/webService";

export const LookupsContext = createContext();

export const LookupsContextProvider = ({ children }) => {
  const [lookupsList, setLookupsList] = useState([]);
  const [singleLookup, setSingleLookup] = useState(null);
  const onFailRequest = { status: false, message: ERROR_MESSAGE };
  const getLookupsList = async (
    page = 1,
    paginateStatus = true,
    filteredQuery = "",
    responseCallback
  ) => {
    try {
      const query = `page=${page}&paginate=${paginateStatus}${filteredQuery}`;
      const res = await callRequest(GET_LOOKUPS, {}, "", query);
      if (res) {
        setLookupsList(res);
        if (responseCallback) responseCallback({ status: true });
      }
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const getSingleLookup = async (id, responseCallback) => {
    try {
      const res = await callRequest(GET_SINGLE_LOOKUPS, {}, `${id}`, "");
      if (res) {
        setSingleLookup(res);
      }
      if (responseCallback) responseCallback({ status: true });
    } catch (err) {
      console.log("err", err);
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const createLookup = async (payload, responseCallback) => {
    try {
      const res = await callRequest(CREATE_LOOKUPS, payload, "", "");
      if (res) {
        if (responseCallback) responseCallback(res);
        toast.success(LOOKUP_CREATE_MESSAGE);
      }
      if (responseCallback)
        responseCallback({ status: false, message: res.message });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const updateLookup = async (payload, id, responseCallback) => {
    try {
      const res = await callRequest(UPDATE_LOOKUPS, payload, `${id}`, "");
      if (res) {
        if (responseCallback) responseCallback(res);
        toast.success(LOOKUP_UPDATE_MESSAGE);
      }
      if (responseCallback)
        responseCallback({ status: false, message: res.message });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const deleteLookup = async (id, responseCallback) => {
    try {
      const res = await callRequest(DELETE_LOOKUPS, {}, `${id}`, "");
      if (res) {
        toast.success(LOOKUP_DELETE_MESSAGE);
        getLookupsList();
        if (responseCallback) responseCallback(res);
      }
      if (responseCallback)
        responseCallback({ status: false, message: res.message });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  return (
    <LookupsContext.Provider
      value={{
        lookupsList,
        singleLookup,
        getSingleLookup,
        getLookupsList,
        createLookup,
        updateLookup,
        deleteLookup,
      }}
    >
      {children}
    </LookupsContext.Provider>
  );
};
