import ApiHandler from "./ApiHandler";

export const ERROR_SOMETHING_WENT_WRONG =
  "Something went wrong, Please try again later";

export const ERROR_NETWORK_NOT_AVAILABLE =
  "Please connect to the working Internet";

export const ERROR_ACCOUNT_BLOCKED =
  "Either your account is blocked or deleted";

export const ERROR_TOKEN_EXPIRE = "Session Expired, Please login again!";

export const REQUEST_TYPE = {
  GET: "get",
  POST: "post",
  DELETE: "delete",
  PUT: "put",
};

// UPLOAD IMAGE

export const UPLOAD_IMAGE = {
  route: "/api/v1/uploader/upload_single",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const GET_PRIVELEGES = {
  route: "/api/v1/permissions",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};
// UPLOAD SIGNATURE

export const UPLOAD_SIGNATURE = {
  route: "/api/v1/agreements/:id/sign",
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};
// DASHBOARD STATES

export const DASHBOARD_STATES = {
  route: "/api/v1/reports/stats",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

// AGREEMENT STATUS

export const GET_AGREEMENT_STATUS = {
  route: "/api/v1/agreement_statuses",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

// AGREEMENT CONTENT

export const GET_AGREEMENT_CONTENT = {
  route: "/api/v1/agreement_templates",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};
// API AUTH ROUTES

export const USER_LOGIN = {
  route: "/api/v1/users/login",
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};
export const USER_LOGOUT = {
  route: "/api/v1/users/logout",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

// API ROLES ROUTES

export const GET_ROLES = {
  route: "/api/v1/roles",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_ROLES = {
  route: "/api/v1/roles",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_ROLES = {
  route: "/api/v1/roles",
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const UPDATE_ROLES_PERMISSION = {
  route: "/api/v1/roles/:id/permissions",
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};
export const DELETE_ROLES = {
  route: "/api/v1/roles",
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_SINGLE_ROLES = {
  route: "/api/v1/roles",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

// API LOOKUPS ROUTES

export const GET_LOOKUPS = {
  route: "/api/v1/lookups",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_LOOKUPS = {
  route: "/api/v1/lookups",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_LOOKUPS = {
  route: "/api/v1/lookups",
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const DELETE_LOOKUPS = {
  route: "/api/v1/lookups",
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_SINGLE_LOOKUPS = {
  route: "/api/v1/lookups",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

// API LOCATIONS ROUTES

export const GET_LOCATIONS = {
  route: "/api/v1/locations",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_LOCATIONS = {
  route: "/api/v1/locations",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_LOCATIONS = {
  route: "/api/v1/locations",
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const DELETE_LOCATIONS = {
  route: "/api/v1/locations",
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_SINGLE_LOCATIONS = {
  route: "/api/v1/locations",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

// API PRINCIPALS ROUTES

export const GET_PRINCIPALS = {
  route: "/api/v1/principals",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_PRINCIPALS = {
  route: "/api/v1/principals",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_PRINCIPALS = {
  route: "/api/v1/principals",
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const DELETE_PRINCIPALS = {
  route: "/api/v1/principals",
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_SINGLE_PRINCIPALS = {
  route: "/api/v1/principals",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

// API COMPANIES ROUTES

export const GET_COMPANIES = {
  route: "/api/v1/companies",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_COMPANIES = {
  route: "/api/v1/companies",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_COMPANIES = {
  route: "/api/v1/companies",
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const DELETE_COMPANIES = {
  route: "/api/v1/companies",
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_SINGLE_COMPANIES = {
  route: "/api/v1/companies",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

// API USERS ROUTES

export const GET_USERS = {
  route: "/api/v1/users",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_USERS = {
  route: "/api/v1/users",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_USERS = {
  route: "/api/v1/users",
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const DELETE_USERS = {
  route: "/api/v1/users",
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_SINGLE_USERS = {
  route: "/api/v1/users",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

// API AGREEMENT ROUTES

export const GET_AGREEMENT = {
  route: "/api/v1/agreements",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_AGREEMENT = {
  route: "/api/v1/agreements",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_AGREEMENT = {
  route: "/api/v1/agreements",
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const DELETE_AGREEMENT = {
  route: "/api/v1/agreements",
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_SINGLE_AGREEMENT = {
  route: "/api/v1/agreements",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

// API TRANSACTION ROUTES

export const GET_TRANSACTION = {
  route: "/api/v1/transactions",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_TRANSACTION = {
  route: "/api/v1/transactions",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_TRANSACTION = {
  route: "/api/v1/transactions",
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const DELETE_TRANSACTION = {
  route: "/api/v1/transactions",
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const CANCEL_TRANSACTION = {
  route: "/api/v1/transactions",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const GET_SINGLE_TRANSACTION = {
  route: "/api/v1/transactions",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const callRequest = async (url, data, parameter, query, header = {}) => {
  let _header = header;
  if (url.access_token_required) {
    const _access_token = localStorage.getItem("burque_token");
    if (_access_token) {
      _header = {
        ..._header,
        ...{
          Authorization: `Bearer ${_access_token}`,
        },
      };
    }
  }

  let _url =
    parameter && parameter !== null ? `${url.route}/${parameter}` : url.route;
  if (query && query !== null) {
    _url = `${_url}?${query}`;
  }
  let response = await ApiHandler(url.type, _url, data, _header);
  return response;
};
