import React, { createContext, useState } from "react";
import { errorToast } from "../utils";
import { ERROR_MESSAGE, TRANSACTION_CANCELLED } from "../constants";
import {
  GET_SINGLE_TRANSACTION,
  GET_TRANSACTION,
  callRequest,
  CANCEL_TRANSACTION,
} from "../services/webService";

export const TransactionsContext = createContext();

export const TransactionsContextProvider = ({ children }) => {
  const [transactionsList, setTransactionsList] = useState([]);
  const [singleTransaction, setSingleTransaction] = useState(null);
  const onFailRequest = { status: false, message: ERROR_MESSAGE };
  const getTransactionsList = async (
    page = 1,
    paginateStatus = true,
    filteredQuery = "",
    responseCallback
  ) => {
    try {
      const query = `page=${page}&paginate=${paginateStatus}${filteredQuery}`;
      const res = await callRequest(GET_TRANSACTION, {}, "", query);
      if (res) {
        setTransactionsList(res);
      }
      if (responseCallback) responseCallback({ status: true });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const getSingleTransaction = async (id, responseCallback) => {
    try {
      const res = await callRequest(GET_SINGLE_TRANSACTION, {}, `${id}`, "");
      if (res) {
        setSingleTransaction(res);
      }
      if (responseCallback) responseCallback({ status: true });
    } catch (err) {
      console.log("err", err);
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };
  
  const cancelTransaction = async (id, responseCallback) => {
    try {
      const formData = new FormData();
      formData.append('status', 'rejected');
      const test = `${CANCEL_TRANSACTION}/${id}/reject`;
      const res = await callRequest(CANCEL_TRANSACTION, formData, `${id}/reject`, "");
      if (res) {
        if (responseCallback) responseCallback(res);
        if (res.status === true) {
          toast.success(TRANSACTION_CANCELLED);
        }
      }
      if (responseCallback)
        responseCallback({ status: false, message: res.message });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  return (
    <TransactionsContext.Provider
      value={{
        singleTransaction,
        getSingleTransaction,
        transactionsList,
        getTransactionsList,
        cancelTransaction,
      }}
    >
      {children}
    </TransactionsContext.Provider>
  );
};
