import { getFormattedDateTime } from "../utils";

export const userDataMauplator = (data) => {
  let user = {};
  user["Name"] = `${data?.first_name} ${data?.last_name}`;
  user["Role"] = data?.role?.name ?? "-";
  user["Location"] = data?.location?.name ?? "-";
  user["Expiry Date"] = data?.expiry_date ?? "-";
  user["Status"] = data?.is_active == "1" ? "Active" : "Inactive";
  user["Created At"] = getFormattedDateTime(data?.created_at);
  user["Updated At"] = getFormattedDateTime(data?.updated_at);

  return user;
};
export const userListMauplator = (list) => {
  let users = [];
  list.map((item) => {
    users.push(userDataMauplator(item));
  });
  return users;
};
