import React, { useContext, useEffect } from "react";
import { Row, Col, Card, Form, Input, Button, Checkbox } from "antd";
import { RolesContext } from "../../context/RolesContext";
import { useParams } from "react-router-dom";
const RoleForm = () => {
  const { id, dataid } = useParams();
  const [form] = Form.useForm();
  const handleFormFinish = () => {
    const credentials = form.getFieldsValue();
  };
  useEffect(() => {
    if (id || dataid) {
      form.setFieldsValue({
        name: "admin",
        code: "AD512",
        privaleges: "all",
      });
    }
  }, []);

  return (
    <div className="layout-content">
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card
            bordered={false}
            className="criclebox cardbody "
            style={{ padding: "20px 40px" }}
          >
            <Form form={form} className="form" onFinish={handleFormFinish}>
              <Row gutter={[24, 0]}>
                <Col xs={24} md={12} lg={8}>
                  <label className="form-lbl">Name:</label>
                  <Form.Item
                    name={"name"}
                    rules={[
                      {
                        required: true,
                        message: "Name can't be empty",
                      },
                    ]}
                  >
                    <Input
                      name="name"
                      disabled={dataid}
                      placeholder=""
                      className="form-input"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <label className="form-lbl">Code:</label>
                  <Form.Item
                    name={"code"}
                    rules={[
                      {
                        required: true,
                        message: "Code can't be empty",
                      },
                    ]}
                  >
                    <Input
                      name="code"
                      disabled={dataid}
                      placeholder=""
                      className="form-input"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <label className="form-lbl">Privaleges:</label>
                  <Form.Item
                    name={"privaleges"}
                    rules={[
                      {
                        required: true,
                        message: "Privaleges can't be empty",
                      },
                    ]}
                  >
                    <Input
                      disabled={dataid}
                      name="privaleges"
                      placeholder=""
                      className="form-input"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <Form.Item name={"activated"}>
                    <Checkbox
                      name="activated"
                      defaultChecked={true}
                      disabled={dataid}
                    />
                    <label className="form-sublbl">Is Active?</label>
                  </Form.Item>
                </Col>
                {!dataid && (
                  <Col xs={24} md={24} lg={24}>
                    <Form.Item>
                      <Button htmlType="submit" className="form-btn">
                        {id ? "Update" : "Create"}
                      </Button>
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default RoleForm;
