import React, { useContext, useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Button,
  Checkbox,
  DatePicker,
  Select,
} from "antd";
import moment from "moment";
import { getFormattedDate } from "../../utils";
import { LocationsContext } from "../../context/LocationContext";
import { UsersContext } from "../../context/UsersContext";
import { RolesContext } from "../../context/RolesContext";
import { GenericContext } from "../../context/genericContext";
import { useHistory, useParams } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Link } from "react-router-dom";
import { USERS_ROUTE, UPDATE_USERS_ROUTE, PERMISSIONS } from "../../constants";
const UsersForm = () => {
  const { id, dataid } = useParams();
  const history = useHistory();
  const paramid = id || dataid;
  const [isLoading, setLoading] = useState(false);
  const [isActivated, setActivated] = useState(false);
  const [selectedRole, setselectedRole] = useState(0);
  const [locationData, setLocationData] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const { locationsList, getLocationsList } = useContext(LocationsContext);
  const { rolesList, getRolesList } = useContext(RolesContext);
  const { getUserPermission, userPermissions } = useContext(GenericContext);
  const UpdateAccess = PERMISSIONS.find(
    (x) => x.route === UPDATE_USERS_ROUTE.replace("/:id", "")
  );
  const {
    singleUser,
    getSingleUser,
    createUser,
    updateUser,
    usersList,
    getUsersList,
  } = useContext(UsersContext);
  const [form] = Form.useForm();

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setActivated(isChecked);
  };

  const handleFormFinish = () => {
    const credentials = form.getFieldsValue();
    credentials["is_active"] = isActivated;
    setLoading(true);
    if (id) {
      if(credentials["role_id"] === 3 && credentials["representative_id"] === undefined) {
        credentials["representative_id"] = null
      }
      credentials["expiry_date"] = getFormattedDate(credentials.expiry_date);
      updateUser(credentials, id, (res) => {
        setLoading(false);
        if (res.status) {
          history.push(USERS_ROUTE);
        }
      });
    } else {
      credentials["expiry_date"] = getFormattedDate(credentials.expiry_date);
      createUser(credentials, (res) => {
        setLoading(false);
        if (res.status) {
          history.push(USERS_ROUTE);
        }
      });
    }
  };
  useEffect(() => {
    if (paramid) {
      getSingleUser(paramid);
    }
    getUserPermission();
    getLocationsList(1, false, "");
    getRolesList(1, false, "");
    getUsersList(1, false, "");
  }, []);

  useEffect(() => {
    setLocationData(
      Array.isArray(locationsList) ? locationsList : locationsList.data
    );
  }, [locationsList]);

  useEffect(() => {
    setRolesData(Array.isArray(rolesList) ? rolesList : rolesList.data);
  }, [rolesList]);
  useEffect(() => {
    setUsersData(
      Array.isArray(usersList)
        ? usersList.filter((x) => x.role_id === 4)
        : usersList.data.filter((x) => x.role_id === 4)
    );
  }, [usersList]);

  useEffect(() => {
    if (singleUser && paramid) {
      setActivated(singleUser.is_active);
      form.setFieldsValue({
        email: singleUser.email,
        first_name: singleUser.first_name,
        last_name: singleUser.last_name,
        location_id: singleUser.location_id,
        password: singleUser.password,
        phone: singleUser.phone,
        principal_id: singleUser.principal_id,
        role_id: singleUser.role_id,
        representative_id: singleUser.representative_id,
        expiry_date: moment(singleUser.expiry_date),
        is_active: singleUser.is_active ? true : false,
      });
      setselectedRole(singleUser.role_id);
    }
  }, [singleUser]);

  return (
    <div className="layout-content">
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          {userPermissions.includes(UpdateAccess.access) && (
            <>
              {dataid && (
                <div style={{ textAlign: "right" }}>
                  <Link to={UPDATE_USERS_ROUTE.replace(":id", paramid)}>
                    <Button className="filter-btn">Edit</Button>
                  </Link>
                </div>
              )}
            </>
          )}
          <Card
            bordered={false}
            className="criclebox cardbody "
            style={{ padding: "20px 40px" }}
          >
            <Form form={form} className="form" onFinish={handleFormFinish}>
              <Row gutter={[24, 0]}>
                <Col xs={24} md={12} lg={8}>
                  <label className="form-lbl">First Name:</label>
                  <Form.Item
                    name={"first_name"}
                    rules={[
                      {
                        required: true,
                        message: "First name can't be empty",
                      },
                    ]}
                  >
                    <Input
                      maxLength={15}
                      name="first_name"
                      disabled={dataid}
                      placeholder=""
                      className="form-input"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <label className="form-lbl">Last Name:</label>
                  <Form.Item
                    name={"last_name"}
                    rules={[
                      {
                        required: true,
                        message: "Last name can't be empty",
                      },
                    ]}
                  >
                    <Input
                      name="last_name"
                      maxLength={15}
                      disabled={dataid}
                      placeholder=""
                      className="form-input"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <label className="form-lbl">Phone Number:</label>
                  <Form.Item
                    name={"phone"}
                    rules={[
                      {
                        min: 8,
                        message: "Number should be greater than 8 character",
                      },
                      {
                        max: 15,
                        message: "Number should be less than 15 character",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      name="phone"
                      disabled={dataid}
                      placeholder=""
                      className="form-input"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={dataid ? 24 : 12}>
                  <label className="form-lbl">Email:</label>
                  <Form.Item
                    name={"email"}
                    rules={[
                      {
                        required: true,
                        message: "Email can't be empty",
                      },
                      {
                        type: "email",
                        message: "Invalid email",
                      },
                    ]}
                  >
                    <Input
                      name="email"
                      disabled={dataid}
                      placeholder=""
                      className="form-input"
                    />
                  </Form.Item>
                </Col>
                {!dataid && (
                  <Col xs={24} md={12} lg={12}>
                    <label className="form-lbl">Password:</label>
                    <Form.Item
                      name={"password"}
                      rules={[
                        {
                          required: id ? false : true,
                          message: "Password can't be empty",
                        },
                        {
                          min: 8,
                          message:
                            "Password should be greater than 8 character",
                        },
                      ]}
                    >
                      <Input
                        name="password"
                        disabled={dataid}
                        placeholder=""
                        className="form-input"
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col xs={24} md={12} lg={8}>
                  <label className="form-lbl">Location:</label>
                  <Form.Item
                    name={"location_id"}
                    rules={[
                      {
                        required: true,
                        message: "please select location",
                      },
                    ]}
                  >
                    <Select
                      placeholder=""
                      name="location_id"
                      disabled={dataid}
                      className="form-select"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {locationData.map((data, index) => (
                        <Select.Option value={data.id} key={index}>
                          {data.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <label className="form-lbl">Role:</label>
                  <Form.Item
                    name={"role_id"}
                    rules={[
                      {
                        required: true,
                        message: "please select role",
                      },
                    ]}
                  >
                    <Select
                      placeholder=""
                      name="role_id"
                      disabled={dataid}
                      className="form-select"
                      showSearch
                      onChange={(e) => {
                        setselectedRole(e);
                      }}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {rolesData.map((data, index) => (
                        <Select.Option value={data.id} key={index}>
                          {data.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={8}>
                  <label className="form-lbl">Expiry Date:</label>
                  <Form.Item
                    name={"expiry_date"}
                    rules={[
                      {
                        required: true,
                        message: "Expiry Date can't be empty",
                      },
                    ]}
                  >
                    <DatePicker
                      disabled={dataid}
                      format="YYYY-MM-DD"
                      disabledDate={(current) =>
                        current.isBefore(moment().subtract(1, "day"))
                      }
                      className="form-input"
                      style={{ width: "100%", minHeight: 40 }}
                    />
                  </Form.Item>
                </Col>
                {selectedRole === 3 && (
                  <Col xs={24} md={12} lg={8}>
                    <label className="form-lbl">Representative:</label>
                    <Form.Item name={"representative_id"}>
                      <Select
                        placeholder=""
                        allowClear
                        name="representative_id"
                        disabled={dataid}
                        className="form-select"
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {usersData.map((data, index) => (
                          <Select.Option value={data.id} key={index}>
                            {data.first_name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                <Col
                  xs={24}
                  md={12}
                  lg={12}
                  style={{ display: "flex", alignItems: "flex-end" }}
                >
                  <Form.Item name={"is_active"}>
                    <Checkbox
                      name="is_active"
                      checked={isActivated}
                      onChange={handleCheckboxChange}
                      disabled={dataid}
                    />
                    <label className="form-sublbl">Is Active?</label>
                  </Form.Item>
                </Col>
                {!dataid && (
                  <Col xs={24} md={24} lg={24}>
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        disabled={isLoading}
                        className="form-btn"
                      >
                        {isLoading ? (
                          <ScaleLoader
                            color={"#FFF"}
                            loading={isLoading}
                            height={14}
                            width={2}
                          />
                        ) : id ? (
                          "Update"
                        ) : (
                          "Create"
                        )}
                      </Button>
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UsersForm;
