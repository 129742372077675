import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import { errorToast } from "../utils";
import {
  ERROR_MESSAGE,
  PRINCIPAL_DELETE_MESSAGE,
  PRINCIPAL_UPDATE_MESSAGE,
  PRINCIPAL_CREATE_MESSAGE,
} from "../constants";
import {
  GET_PRINCIPALS,
  CREATE_PRINCIPALS,
  UPDATE_PRINCIPALS,
  DELETE_PRINCIPALS,
  GET_SINGLE_PRINCIPALS,
  callRequest,
} from "../services/webService";

export const PrincipalsContext = createContext();

export const PrincipalsContextProvider = ({ children }) => {
  const [principalsList, setPrincipalsList] = useState([]);
  const [singlePrincipal, setSinglePrincipal] = useState(null);
  const onFailRequest = { status: false, message: ERROR_MESSAGE };
  const getPrincipalsList = async (
    page = 1,
    paginateStatus = true,
    filteredQuery = "",
    responseCallback
  ) => {
    try {
      const query = `page=${page}&paginate=${paginateStatus}${filteredQuery}`;
      const res = await callRequest(GET_PRINCIPALS, {}, "", query);
      if (res) {
        setPrincipalsList(res);
      }
      if (responseCallback) responseCallback({ status: true });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const getSinglePrincipal = async (id, responseCallback) => {
    try {
      const res = await callRequest(GET_SINGLE_PRINCIPALS, {}, `${id}`, "");
      if (res) {
        setSinglePrincipal(res);
      }
      if (responseCallback) responseCallback({ status: true });
    } catch (err) {
      console.log("err", err);
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const createPrincipal = async (payload, responseCallback) => {
    try {
      const res = await callRequest(CREATE_PRINCIPALS, payload, "", "");
      if (res) {
        if (responseCallback) responseCallback(res);
        if (res.status === true) {
          toast.success(PRINCIPAL_CREATE_MESSAGE);
        }
      } else {
        if (responseCallback)
          responseCallback({ status: false, message: res.message });
      }
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const updatePrincipal = async (payload, id, responseCallback) => {
    try {
      const res = await callRequest(UPDATE_PRINCIPALS, payload, `${id}`, "");
      if (res) {
        if (responseCallback) responseCallback(res);
        toast.success(PRINCIPAL_UPDATE_MESSAGE);
      }
      if (responseCallback)
        responseCallback({ status: false, message: res.message });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const deletePrincipal = async (id, responseCallback) => {
    try {
      const res = await callRequest(DELETE_PRINCIPALS, {}, `${id}`, "");
      if (res) {
        toast.success(PRINCIPAL_DELETE_MESSAGE);
        getPrincipalsList();
        if (responseCallback) responseCallback(res);
      }
      if (responseCallback)
        responseCallback({ status: false, message: res.message });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  return (
    <PrincipalsContext.Provider
      value={{
        principalsList,
        singlePrincipal,
        getSinglePrincipal,
        getPrincipalsList,
        createPrincipal,
        updatePrincipal,
        deletePrincipal,
      }}
    >
      {children}
    </PrincipalsContext.Provider>
  );
};
