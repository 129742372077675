import React from "react";
import { Table, Card, Typography, Button } from "antd";
import {
  FilterFilled,
  UsergroupAddOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { utils, writeFile } from "xlsx";
const { Title } = Typography;
function AdminDataTable({
  title = "",
  filterHandler,
  data,
  columns,
  pagination,
  exportFunc,
  handleGetAllFunc,
  sheet,
}) {
  const handleExport = () => {
    const excelData = exportFunc();
    const worksheet = utils.json_to_sheet(excelData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    writeFile(workbook, `${sheet}.xlsx`);
  };
  const filteredData = data?.map((item, index) => {
    return { ...item, key: index + 1 };
  });
  return (
    <Card
      bordered={false}
      className="criclebox cardbody "
      style={{ minHeight: 360 }}
    >
      <div className="project-ant">
        <Title level={5}>{title}</Title>
        <div>
          {sheet === "users" && (
            <>
              <Button
                className="filter-btn"
                style={{ marginRight: 10 }}
                onClick={handleGetAllFunc}
              >
                <UsergroupAddOutlined />
                All Users
              </Button>
              <Button
                className="filter-btn"
                style={{ marginRight: 10 }}
                onClick={()=>{handleExport()}}
              >
                <ExportOutlined />
                Export
              </Button>
            </>
          )}
          <Button className="filter-btn" onClick={filterHandler}>
            <FilterFilled />
            Filter
          </Button>
        </div>
      </div>
      <div className="ant-list-box ">
        <Table
          columns={columns}
          dataSource={filteredData}
          pagination={{ showSizeChanger: false, ...pagination }}
        />
      </div>
    </Card>
  );
}

export default AdminDataTable;
