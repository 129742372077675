import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import { errorToast } from "../utils";
import {
  ERROR_MESSAGE,
  COMPANY_DELETE_MESSAGE,
  COMPANY_UPDATE_MESSAGE,
  COMPANY_CREATE_MESSAGE,
} from "../constants";
import {
  GET_COMPANIES,
  CREATE_COMPANIES,
  UPDATE_COMPANIES,
  DELETE_COMPANIES,
  GET_SINGLE_COMPANIES,
  callRequest,
} from "../services/webService";

export const CompanyContext = createContext();

export const CompanyContextProvider = ({ children }) => {
  const [companyList, setCompanyList] = useState([]);
  const [singleCompany, setSingleCompany] = useState(null);
  const onFailRequest = { status: false, message: ERROR_MESSAGE };
  const getCompanyList = async (
    page = 1,
    paginateStatus = true,
    filteredQuery = "",
    responseCallback
  ) => {
    try {
      const query = `page=${page}&paginate=${paginateStatus}${filteredQuery}`;
      const res = await callRequest(GET_COMPANIES, {}, "", query);
      if (res) {
        setCompanyList(res);
      }
      if (responseCallback) responseCallback({ status: true });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const getSingleCompany = async (id, responseCallback) => {
    try {
      const res = await callRequest(GET_SINGLE_COMPANIES, {}, `${id}`, "");
      if (res) {
        setSingleCompany(res);
      }
      if (responseCallback) responseCallback({ status: true });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const createCompany = async (payload, responseCallback) => {
    try {
      const res = await callRequest(CREATE_COMPANIES, payload, "", "");
      if (res) {
        if (responseCallback) responseCallback(res);
        toast.success(COMPANY_CREATE_MESSAGE);
      }
      if (responseCallback)
        responseCallback({ status: false, message: res.message });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const updateCompany = async (payload, id, responseCallback) => {
    try {
      const res = await callRequest(UPDATE_COMPANIES, payload, `${id}`, "");
      if (res) {
        if (responseCallback) responseCallback(res);
        toast.success(COMPANY_UPDATE_MESSAGE);
      }
      if (responseCallback)
        responseCallback({ status: false, message: res.message });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const deleteCompany = async (id, responseCallback) => {
    try {
      const res = await callRequest(DELETE_COMPANIES, {}, `${id}`, "");
      if (res) {
        toast.success(COMPANY_DELETE_MESSAGE);
        getCompanyList();
        if (responseCallback) responseCallback(res);
      }
      if (responseCallback)
        responseCallback({ status: false, message: res.message });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  return (
    <CompanyContext.Provider
      value={{
        companyList,
        singleCompany,
        getSingleCompany,
        getCompanyList,
        createCompany,
        updateCompany,
        deleteCompany,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};
