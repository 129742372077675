import React, { useState, useContext } from "react";
import { GenericContext } from "../../context/genericContext";
import { ScaleLoader } from "react-spinners";
import "./styles.scss";
const SignUploader = ({
  text,
  setText,
  desc,
  isImage = true,
  setImage,
  image,
  name,
  agreementid,
  isOnlyImage = false,
}) => {
  const { uploadSignature } = useContext(GenericContext);
  const [loading, setLoading] = useState(false);
  const [error, seterror] = useState("");
  const sign = name.split("_");

  const handleSubmit = () => {
    setLoading(true);
    const payload = {
      [`${name}`]: true,
    };
    uploadSignature(payload, agreementid, (res) => {
      setLoading(false);
      if (res.status) {
        setImage(true);
        setText(res.message);
      }
    });
  };

  return (
    <>
      <div className={`sign-uploader-box ${image && "active"}`}>
        {loading ? (
          <ScaleLoader height={15} width={2} color="#096dd9" />
        ) : image ? (
          <p>{text}</p>
        ) : <p>{desc}</p>}
      </div>
      <p className="errorMessage">{error}</p>
    </>
  );
};

export default SignUploader;
