import React, { useEffect, useState, useContext } from "react";
import { Card, Typography, Col, Row, Tooltip } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import AdminDataTable from "../../Layouts/partials/data-table";
import { RolesContext } from "../../context/RolesContext";
import { ConfirmationModal } from "../../components/Modals/index";
import { Link } from "react-router-dom";
import {
  CREATE_REPORT_ROUTE,
  UPDATE_REPORT_ROUTE,
  REPORT_DETAIL_ROUTE,
} from "../../constants";

const { Title } = Typography;

function Report() {
  const {
    getSingleRole,
    rolesList,
    singleRole,
    getRolesList,
    createRole,
    updateRole,
    deleteRole,
    loading,
  } = useContext(RolesContext);

  const [selectedData, setSelectedData] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [page, setPage] = useState(1);
  const { meta, data } = [];
  const paginationConfig = {
    pageSize: meta?.per_page,
    total: meta?.total,
  };

  // useEffect(() => {
  //   getRolesList(page, true, (res) => {});
  // }, [page]);

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "1",
      render: (data, value, i) => (
        <b>{(page == 1 ? 0 : (page - 1) * 10) + (i + 1)}</b>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "2",
      render: (data, value) => {
        return <span>{data}</span>;
      },
    },

    {
      title: "Action",
      key: "3",
      render: (data, record) => {
        return (
          <div className="actions">
            <Link
              to={REPORT_DETAIL_ROUTE.replace(":dataid", data.id)}
              className="update"
            >
              <Tooltip title="Report Detail">
                <EyeOutlined />
              </Tooltip>
            </Link>
            <Link
              to={UPDATE_REPORT_ROUTE.replace(":id", data.id)}
              className="update"
            >
              <Tooltip title="Update Report">
                <EditOutlined />
              </Tooltip>
            </Link>
            {/* <span style={{ opacity: data.is_default ? 0.3 : 1 }}>
              {data.is_default ? (
                <DeleteOutlined style={{ cursor: "auto" }} />
              ) : (
                <Tooltip title="Delete Report">
                  <DeleteOutlined onClick={() => handleConfirmModal(record)} />
                </Tooltip>
              )}
            </span> */}
          </div>
        );
      },
    },
  ];

  //HANDLERS

  const handleConfirmModal = (role) => {
    setSelectedData(role);
    setShowConfirmationModal(true);
  };
  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleDeleteRole = (id) => {
    deleteRole(id);
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <div className="add-btn">
              <Link to={CREATE_REPORT_ROUTE}>Create Report</Link>
            </div>
            <Card
              bordered={false}
              className="criclebox cardbody "
              style={{ minHeight: 360 }}
            >
              <div className="project-ant">
                <div>
                  <Title level={5}>Reports</Title>
                  <Paragraph className="lastweek"></Paragraph>
                </div>
              </div>
              <AdminDataTable
                data={data}
                columns={columns}
                pagination={{ ...paginationConfig, onChange: handlePageChange }}
              />
            </Card>
          </Col>
        </Row>
      </div>
      {showConfirmationModal && (
        <ConfirmationModal
          handleClose={handleConfirmationModalClose}
          title={"Delete Report?"}
          description={"Are you sure you want to delete this principal?"}
          deleteHandler={handleDeleteRole}
          selectedData={selectedData}
        />
      )}
    </>
  );
}

export default Report;
