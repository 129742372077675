import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import { errorToast } from "../utils";
import {
  ERROR_MESSAGE,
  AGREEMENT_DELETE_MESSAGE,
  AGREEMENT_UPDATE_MESSAGE,
  AGREEMENT_CREATE_MESSAGE,
} from "../constants";
import {
  GET_AGREEMENT,
  CREATE_AGREEMENT,
  UPDATE_AGREEMENT,
  DELETE_AGREEMENT,
  GET_SINGLE_AGREEMENT,
  callRequest,
} from "../services/webService";

export const AgreementContext = createContext();

export const AgreementContextProvider = ({ children }) => {
  const [agreementsList, setAgreementsList] = useState([]);
  const [singleAgreement, setSingleAgreement] = useState(null);
  const onFailRequest = { status: false, message: ERROR_MESSAGE };
  const getAgreementsList = async (
    page = 1,
    paginateStatus = true,
    filteredQuery = "",
    responseCallback
  ) => {
    try {
      const query = `page=${page}&paginate=${paginateStatus}${filteredQuery}`;
      const res = await callRequest(GET_AGREEMENT, {}, "", query);
      if (res) {
        setAgreementsList(res);
      }
      if (responseCallback) responseCallback({ status: true });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const getSingleAgreement = async (id, responseCallback) => {
    try {
      const res = await callRequest(GET_SINGLE_AGREEMENT, {}, `${id}`, "");
      if (res) {
        setSingleAgreement(res);
      }
      if (responseCallback) responseCallback({ status: true });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const createAgreement = async (payload, responseCallback) => {
    try {
      const res = await callRequest(CREATE_AGREEMENT, payload, "", "");
      if (res) {
        if (responseCallback) responseCallback(res);
        if (res.status === true) {
          toast.success(AGREEMENT_CREATE_MESSAGE);
        }
      }
      if (responseCallback)
        responseCallback({ status: false, message: res.message });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const updateAgreement = async (payload, id, responseCallback) => {
    try {
      const res = await callRequest(UPDATE_AGREEMENT, payload, `${id}`, "");
      if (res) {
        if (responseCallback) responseCallback(res);
        if (res.status === true) {
          toast.success(AGREEMENT_UPDATE_MESSAGE);
        }
      }
      if (responseCallback)
        responseCallback({ status: false, message: res.message });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const deleteAgreement = async (id, responseCallback) => {
    try {
      const res = await callRequest(DELETE_AGREEMENT, {}, `${id}`, "");
      if (res) {
        toast.success(AGREEMENT_DELETE_MESSAGE);
        getAgreementsList();
        if (responseCallback) responseCallback(res);
      }
      if (responseCallback)
        responseCallback({ status: false, message: res.message });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  return (
    <AgreementContext.Provider
      value={{
        agreementsList,
        singleAgreement,
        getSingleAgreement,
        getAgreementsList,
        createAgreement,
        updateAgreement,
        deleteAgreement,
      }}
    >
      {children}
    </AgreementContext.Provider>
  );
};
