import React, { createContext, useState } from "react";
import { errorToast } from "../utils";
import { ERROR_MESSAGE } from "../constants";
import {
  DASHBOARD_STATES,
  GET_AGREEMENT_STATUS,
  GET_AGREEMENT_CONTENT,
  UPLOAD_IMAGE,
  callRequest,
  UPLOAD_SIGNATURE,
  GET_PRIVELEGES,
} from "../services/webService";

const onFailRequest = { status: false, message: ERROR_MESSAGE };
export const GenericContext = createContext();
export const GenericContextProvider = ({ children }) => {
  const [agreementStatus, setAgreementStatus] = useState([]);
  const [agreementContents, setagreementContents] = useState([]);
  const [userPermissions, setuserPermissions] = useState([]);
  const [priveleges, setPriveleges] = useState([]);
  const [dashboardStates, setDashboardStates] = useState(null);

  const getDashboardStates = async (responseCallback) => {
    try {
      const res = await callRequest(DASHBOARD_STATES, {}, "", "");
      if (res) {
        setDashboardStates(res.data);
      }
      if (responseCallback) responseCallback(res);
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const getAgreementStatusList = async (responseCallback) => {
    try {
      const res = await callRequest(GET_AGREEMENT_STATUS, {}, "", "");
      if (res) {
        setAgreementStatus(res);
      }
      if (responseCallback) responseCallback({ status: true });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const getAgreementContent = async (responseCallback) => {
    try {
      const res = await callRequest(GET_AGREEMENT_CONTENT, {}, "", "");
      if (res) {
        setagreementContents(res);
      }
      if (responseCallback) responseCallback({ status: true });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const getPriveleges = async (responseCallback) => {
    try {
      const query = `page=1&paginate=false&limit=100`;
      const res = await callRequest(GET_PRIVELEGES, {}, "", query);
      if (res) {
        setPriveleges(res.data);
      }
      if (responseCallback) responseCallback({ status: true });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const getUserPermission = () => {
    let allPermission = [];
    const permission = JSON.parse(localStorage.getItem("burque_acess"));
    permission.map((x) => {
      allPermission.push(x.id);
      return null;
    });
    setuserPermissions(allPermission);
  };

  const uploadImage = async (payload, responseCallback) => {
    try {
      const res = await callRequest(UPLOAD_IMAGE, payload, "", "", {
        "Content-Type": "multipart/form-data",
      });
      // if (res) {
      //   setActivationStatus(res);
      // }
      if (responseCallback) responseCallback(res);
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const uploadSignature = async (payload, params, responseCallback) => {
    try {
      let Route = UPLOAD_SIGNATURE;
      Route["route"] = UPLOAD_SIGNATURE.route.replace(":id", params);
      const res = await callRequest(Route, payload, "", "");
      if (responseCallback) responseCallback(res);
    } catch (err) {
      console.log("err", err);
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  return (
    <GenericContext.Provider
      value={{
        getAgreementStatusList,
        uploadImage,
        uploadSignature,
        getPriveleges,
        priveleges,
        agreementStatus,
        getDashboardStates,
        dashboardStates,
        getUserPermission,
        userPermissions,
        getAgreementContent,
        agreementContents,
      }}
    >
      {children}
    </GenericContext.Provider>
  );
};
