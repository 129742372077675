import React, { useContext, useState, useEffect } from "react";
import { Row, Col, Card, Form, Input, Button, Checkbox, Select } from "antd";
import { LookupsContext } from "../../context/LookupContext";
import { LocationsContext } from "../../context/LocationContext";
import { GenericContext } from "../../context/genericContext";
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  LOCATION_ROUTE,
  UPDATE_LOCATION_ROUTE,
  PERMISSIONS,
} from "../../constants";
import ScaleLoader from "react-spinners/ScaleLoader";
import { toast } from "react-toastify";
const LocationForm = () => {
  const { id, dataid } = useParams();
  const history = useHistory();
  const paramid = id || dataid;
  const [isLoading, setLoading] = useState(false);
  const [isActivated, setActivated] = useState(true);
  const [lookupIdData, setlookupIdData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const { lookupsList, getLookupsList } = useContext(LookupsContext);
  const {
    locationsList,
    singleLocation,
    getSingleLocation,
    getLocationsList,
    createLocation,
    updateLocation,
  } = useContext(LocationsContext);
  const { getUserPermission, userPermissions } = useContext(GenericContext);
  const UpdateAccess = PERMISSIONS.find(
    (x) => x.route === UPDATE_LOCATION_ROUTE.replace("/:id", "")
  );
  const [form] = Form.useForm();
  const handleFormFinish = () => {
    const credentials = form.getFieldsValue();
    credentials["is_active"] = isActivated;
    setLoading(true);
    if (id) {
      updateLocation(credentials, id, (res) => {
        setLoading(false);
        if (res.status) {
          history.push(LOCATION_ROUTE);
        } else {
          if (res.message.errors) {
            toast.error(res.message.errors[0].message);
          }
        }
      });
    } else {
      createLocation(credentials, (res) => {
        setLoading(false);
        if (res.status) {
          history.push(LOCATION_ROUTE);
        }
      });
    }
  };
  useEffect(() => {
    if (paramid) {
      getSingleLocation(paramid);
    }
    getUserPermission();
    getLookupsList(1, false, "");
    getLocationsList(1, false, "");
  }, []);
  useEffect(() => {
    setlookupIdData(
      Array.isArray(lookupsList) ? lookupsList : lookupsList.data
    );

    setLocationData(
      Array.isArray(locationsList) ? locationsList : locationsList.data
    );
  }, [locationsList, lookupsList]);
  useEffect(() => {
    if (singleLocation && paramid) {
      form.setFieldsValue({
        type: singleLocation.type,
        name: singleLocation.name,
        code: singleLocation.code,
        lookup_id: singleLocation.lookup_id,
        parent_location_id: singleLocation.parent_location_id,
      });
      setActivated(singleLocation.is_active);
    }
  }, [singleLocation]);

  return (
    <div className="layout-content">
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          {userPermissions.includes(UpdateAccess.access) && (
            <>
              {dataid && (
                <div style={{ textAlign: "right" }}>
                  <Link to={UPDATE_LOCATION_ROUTE.replace(":id", paramid)}>
                    <Button className="filter-btn">Edit</Button>
                  </Link>
                </div>
              )}
            </>
          )}
          <Card
            bordered={false}
            className="criclebox cardbody "
            style={{ padding: "20px 40px" }}
          >
            <Form form={form} className="form" onFinish={handleFormFinish}>
              <Row gutter={[24, 0]}>
                <Col xs={24} md={12} lg={8}>
                  <label className="form-lbl">Name:</label>
                  <Form.Item
                    name={"name"}
                    rules={[
                      {
                        required: true,
                        message: "Name can't be empty",
                      },
                    ]}
                  >
                    <Input
                      name="name"
                      disabled={dataid}
                      placeholder=""
                      className="form-input"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <label className="form-lbl">Code:</label>
                  <Form.Item
                    name={"code"}
                    rules={[
                      {
                        required: true,
                        message: "Code can't be empty",
                      },
                    ]}
                  >
                    <Input
                      name="code"
                      disabled={dataid}
                      placeholder=""
                      className="form-input"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <label className="form-lbl">Lookup:</label>
                  <Form.Item
                    name={"lookup_id"}
                    rules={[
                      {
                        required: true,
                        message: "please select lookup",
                      },
                    ]}
                  >
                    <Select
                      placeholder=""
                      name="lookup_id"
                      disabled={dataid}
                      className="form-select"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {lookupIdData.map((data, index) => (
                        <Select.Option value={data.id} key={index}>
                          {data.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <label className="form-lbl">Parent Location:</label>
                  <Form.Item name={"parent_location_id"}>
                    <Select
                      placeholder=""
                      name="parent_location_id"
                      disabled={dataid}
                      className="form-select"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {locationData.map((data, index) => (
                        <Select.Option value={data.id} key={index}>
                          {data.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  md={12}
                  lg={8}
                  style={{ display: "flex", alignItems: "flex-end" }}
                >
                  <Form.Item name={"is_active"}>
                    <Checkbox
                      name="is_active"
                      defaultChecked={isActivated}
                      onChange={() => {
                        setActivated(!isActivated);
                      }}
                      disabled={dataid}
                    />
                    <label className="form-sublbl">Is Active?</label>
                  </Form.Item>
                </Col>
                {!dataid && (
                  <Col xs={24} md={24} lg={24}>
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        disabled={isLoading}
                        className="form-btn"
                      >
                        {isLoading ? (
                          <ScaleLoader
                            color={"#FFF"}
                            loading={isLoading}
                            height={14}
                            width={2}
                          />
                        ) : id ? (
                          "Update"
                        ) : (
                          "Create"
                        )}
                      </Button>
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default LocationForm;
