import React, { useContext, useEffect } from "react";
import { Col, Row } from "antd";
import AdminAnalayticsCard from "../../Layouts/partials/analytics-card";
import { DASHBOARD_ANALYTICS } from "../../constants";
import { GenericContext } from "../../context/genericContext";
function AdminDashboard() {
  const {
    getDashboardStates,
    dashboardStates,
    getUserPermission,
    userPermissions,
  } = useContext(GenericContext);
  useEffect(() => {
    getDashboardStates((res) => {});
    getUserPermission();
  }, []);
  return (
    <>
      <div className="layout-content">
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {DASHBOARD_ANALYTICS.map(
            (item, index) =>
              userPermissions.includes(item.access) && (
                <Col xs={24} md={12} lg={8} className="mb-40" key={index}>
                  <AdminAnalayticsCard
                    title={item.title}
                    thumb={item.icon}
                    total={dashboardStates ? dashboardStates[item.id] : 0}
                  />
                </Col>
              )
          )}
        </Row>
      </div>
    </>
  );
}

export default AdminDashboard;
