import React, { useContext, useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  Alert,
} from "antd";
import { PrincipalsContext } from "../../context/PrincipalContext";
import { UsersContext } from "../../context/UsersContext";
import { CompanyContext } from "../../context/CompanyContext";
import { GenericContext } from "../../context/genericContext";
import { AgreementContext } from "../../context/agreementContext";
import { useHistory, useParams } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import ImageUploader from "../../components/imageUploader";
import SignUploader from "../../components/signUploader";
import {
  AGREEMENT_DURATION_TYPES,
  AGREEMENT_ROUTE,
  UPDATE_AGREEMENT_ROUTE,
  PERMISSIONS,
  AGREEMENT_CONTENT_ROUTE,
  AGENCY_CONTENT_ROUTE,
} from "../../constants";
import { DeleteOutlined } from "@ant-design/icons";
import { getFormattedDate, formatCurrency } from "../../utils";
import { Link } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import ContentEditor from "./editor";
import { ConfirmationModal } from "../../components/Modals";
const AgreementForm = () => {
  const { id, dataid } = useParams();
  const history = useHistory();
  const paramid = id || dataid;
  const [isLoading, setLoading] = useState(false);
  const [isFixed, setFixed] = useState(true);
  const [financierSign, setfinancierSign] = useState(null);
  const [ceoSign, setCeoSign] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [principalData, setPrincipalData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [ceoData, setCeoData] = useState([]);
  const [agreementStatusData, setAgreementStatusData] = useState([]);
  const [apiError, setApiError] = useState([]);
  const [agreementContent, setAgreementContent] = useState("");
  const [agencyAgreementContent, setAgencyAgreementContent] = useState("");
  const [financierSignText, setFinancierSignText] = useState("");
  const [ceoSignText, setCeoSignText] = useState("");
  const [contentPreview, setcontentPreview] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showCompleteConfirmationModal, setCompleteShowConfirmationModal] =
    useState(false);
  const { principalsList, getPrincipalsList } = useContext(PrincipalsContext);
  const { companyList, getCompanyList } = useContext(CompanyContext);
  const { usersList, getUsersList } = useContext(UsersContext);
  const {
    getAgreementStatusList,
    agreementStatus,
    getUserPermission,
    userPermissions,
    getAgreementContent,
    agreementContents,
  } = useContext(GenericContext);
  const UpdateAccess = PERMISSIONS.find(
    (x) => x.route === UPDATE_AGREEMENT_ROUTE.replace("/:id", "")
  );
  const {
    singleAgreement,
    getSingleAgreement,
    createAgreement,
    updateAgreement,
  } = useContext(AgreementContext);
  const [form] = Form.useForm();

  const handleAgreementContent = (e) => {
    // setAgreementContent(e);
  };
  const handleSetFinancierSign = (txt) => {
    setFinancierSignText(txt);
  };
  const handleSetCeoSign = (txt) => {
    setCeoSignText(txt);
  };
  const handleFormFinish = () => {
    const credentials = form.getFieldsValue();
    if (credentials["end_date"] < credentials["start_date"]) {
      toast.error("Ending date should be greater than starting date");
      return;
    }
    const data = new FormData();
    data.append("code", credentials.code);
    data.append("user_id", credentials.user_id);
    data.append("ceo_id", credentials.ceo_id);
    if (credentials.duration_type === AGREEMENT_DURATION_TYPES[1].id) {
      data.append("fixed_date", getFormattedDate(credentials.fixed_date));
    } else if (credentials.duration_type === AGREEMENT_DURATION_TYPES[2].id) {
      data.append("variable_days", credentials.variable_days);
    } else if (credentials.duration_type === AGREEMENT_DURATION_TYPES[0].id) {
      data.append("fixed_days", credentials.fixed_days);
    }
    data.append("duration_type", credentials.duration_type);

    if (credentials.sequence_number) {
      data.append("sequence_number", credentials.sequence_number);
    }
    data.append("amount", credentials.amount);
    data.append("profit", credentials.profit);
    data.append("start_date", getFormattedDate(credentials.start_date));
    data.append("end_date", getFormattedDate(credentials.end_date));
    data.append("principal_ids", credentials["principal_id"].toString());
    data.append("company_ids", credentials["company_id"].toString());
    data.append(
      "agreement_status_id",
      singleAgreement?.agreement_status_id ?? agreementStatus[0]?.id
    );
    data.append("location_ids", 2);
    data.append("financier_sign", financierSign ? 1 : 0);
    data.append("ceo_sign", ceoSign ? 1 : 0);
    data.append("content", agreementContent);
    data.append("agency_content", agencyAgreementContent);
    attachments.map((item) => {
      data.append("attachments[]", item);
    });
    if (attachments.length === 0 && id) {
      data.append("attachments[]", []);
    }
    setLoading(true);
    if (id) {
      updateAgreement(data, id, (res) => {
        setLoading(false);
        if (!res.status) {
          window.scrollTo(0, 0);
          if (res.message.errors) {
            setApiError(res.message.errors);
          }
        } else {
          history.push(AGREEMENT_ROUTE);
        }
      });
    } else {
      createAgreement(data, (res) => {
        data["agreement_status_id"] = 1;
        setLoading(false);
        if (!res.status) {
          window.scrollTo(0, 0);
          if (res.message.errors) {
            setApiError(res.message.errors);
          }
        } else {
          history.push(AGREEMENT_ROUTE);
        }
      });
    }
  };

  const handleUpdateAgreementStatus = (status) => {
    const credentials = form.getFieldsValue();
    if (credentials["end_date"] < credentials["start_date"]) {
      toast.error("Ending date should be greater than starting date");
      return;
    }
    const data = new FormData();
    data.append("code", credentials.code);
    data.append("user_id", credentials.user_id);
    data.append("ceo_id", credentials.ceo_id);
    if (credentials.duration_type === AGREEMENT_DURATION_TYPES[1].id) {
      data.append("fixed_date", getFormattedDate(credentials.fixed_date));
    } else if (credentials.duration_type === AGREEMENT_DURATION_TYPES[2].id) {
      data.append("variable_days", credentials.variable_days);
    } else if (credentials.duration_type === AGREEMENT_DURATION_TYPES[0].id) {
      data.append("fixed_days", credentials.fixed_days);
    }
    data.append("duration_type", credentials.duration_type);

    if (credentials.sequence_number) {
      data.append("sequence_number", credentials.sequence_number);
    }
    data.append("amount", credentials.amount);
    data.append("profit", credentials.profit);
    data.append("start_date", getFormattedDate(credentials.start_date));
    data.append("end_date", getFormattedDate(credentials.end_date));
    data.append("principal_ids", credentials["principal_id"].toString());
    data.append("company_ids", credentials["company_id"].toString());
    data.append(
      "agreement_status_id",
      status === "Cancelled" ? agreementStatus[2]?.id : agreementStatus[3]?.id
    );
    data.append("financier_sign", financierSign ? 1 : 0);
    data.append("ceo_sign", ceoSign ? 1 : 0);
    data.append("location_ids", 2);
    data.append("content", agreementContent);
    data.append("agency_content", agencyAgreementContent);
    attachments.map((item) => {
      data.append("attachments[]", item);
    });
    if (attachments.length === 0 && id) {
      data.append("attachments[]", []);
    }
    setLoading(true);
    updateAgreement(data, id, (res) => {
      setLoading(false);
      if (!res.status) {
        window.scrollTo(0, 0);
        if (res.message.errors) {
          setApiError(res.message.errors);
        }
      } else {
        history.push(AGREEMENT_ROUTE);
      }
    });
  };

  useEffect(() => {
    if (paramid) {
      getSingleAgreement(paramid);
    }
    getPrincipalsList(1, false, "");
    getCompanyList(1, false, "");
    getUsersList(1, false, "");
    getUserPermission();
    getAgreementStatusList();
    if (!paramid) {
      getAgreementContent();
    }
  }, []);

  useEffect(() => {
    setPrincipalData(
      Array.isArray(principalsList) ? principalsList : principalsList.data
    );
  }, [principalsList]);

  useEffect(() => {
    setCompanyData(Array.isArray(companyList) ? companyList : companyList.data);
  }, [companyList]);

  useEffect(() => {
    if (agreementContents) {
      setAgreementContent(agreementContents[0]?.content);
      setAgencyAgreementContent(agreementContents[1]?.content);
    }
  }, [agreementContents]);

  useEffect(() => {
    setUsersData(
      Array.isArray(usersList)
        ? usersList.filter((x) => x.role_id === 3)
        : usersList.data.filter((x) => x.role_id === 3)
    );
    setCeoData(
      Array.isArray(usersList)
        ? usersList.filter((x) => x.role_id === 5)
        : usersList.data.filter((x) => x.role_id === 5)
    );
  }, [usersList]);

  useEffect(() => {
    setAgreementStatusData(
      Array.isArray(agreementStatus) ? agreementStatus : agreementStatus.data
    );
  }, [agreementStatus]);

  useEffect(() => {
    if (singleAgreement && paramid) {
      form.setFieldsValue({
        code: singleAgreement.code,
        user_id: singleAgreement.user_id,
        ceo_id: singleAgreement.ceo_id,
        duration_type: singleAgreement.duration_type,
        variable_days: singleAgreement.variable_days,
        amount: singleAgreement.amount,
        profit: singleAgreement.profit,
        start_date: moment(singleAgreement.start_date),
        end_date: moment(singleAgreement.end_date),
        fixed_date: singleAgreement.fixed_date
          ? moment(singleAgreement.fixed_date)
          : undefined,
        fixed_days: singleAgreement?.fixed_days,
        company_id: singleAgreement.companies.map((x) => {
          return x.id;
        }),
        principal_id: singleAgreement.principals.map((x) => {
          return x.id;
        }),
        agreement_status_id: singleAgreement.agreement_status_id,
        sequence_number: singleAgreement.sequence_number,
      });
      setFixed(singleAgreement.duration_type);
      setfinancierSign(
        singleAgreement.financier_sign === "null"
          ? null
          : singleAgreement.financier_sign
      );
      setCeoSign(
        singleAgreement.ceo_sign === "null" ? null : singleAgreement.ceo_sign
      );
      if (
        singleAgreement.ceo_sign_text &&
        singleAgreement.ceo_sign_text !== ""
      ) {
        setCeoSignText(singleAgreement.ceo_sign_text);
      }
      if (
        singleAgreement.financier_sign_text &&
        singleAgreement.financier_sign_text !== ""
      ) {
        setFinancierSignText(singleAgreement.financier_sign_text);
      }
      if (singleAgreement.attachments) {
        setAttachments(
          singleAgreement.attachments.map((x) => {
            return x.document;
          })
        );
      }
      if (singleAgreement?.content) {
        setAgreementContent(singleAgreement?.content);
      }
      if (singleAgreement?.agency_content) {
        setAgencyAgreementContent(singleAgreement?.agency_content);
      }
    }
  }, [singleAgreement]);

  const setAttachmentsHandler = (image) => {
    setAttachments((attachments) => [...attachments, image]);
  };
  const deleteAttachments = (index) => {
    let data = [...attachments];
    data.splice(index, 1);
    setAttachments(data);
  };
  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleCompleteConfirmationModalClose = () => {
    setCompleteShowConfirmationModal(false);
  };

  return (
    <div className="layout-content">
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          {userPermissions.includes(UpdateAccess.access) && (
            <>
              {dataid && (
                <div style={{ textAlign: "right" }}>
                  <Link to={UPDATE_AGREEMENT_ROUTE.replace(":id", paramid)}>
                    <Button className="filter-btn">Edit</Button>
                  </Link>
                </div>
              )}
            </>
          )}
          {apiError.map((data, index) => (
            <Alert
              type="error"
              key={index}
              message={data.message}
              showIcon
              closable
            />
          ))}
          {contentPreview && (
            <ContentEditor
              onChange={handleAgreementContent}
              content={agreementContent}
            />
          )}
          <Card
            bordered={false}
            className="criclebox cardbody "
            style={{ padding: "20px 40px" }}
          >
            <Form form={form} className="form" onFinish={handleFormFinish}>
              <Row gutter={[24, 0]}>
                <Col xs={24} md={12} lg={8}>
                  <label className="form-lbl">Code:</label>
                  <Form.Item
                    name={"code"}
                    rules={[
                      {
                        required: true,
                        message: "Code can't be empty",
                      },
                    ]}
                  >
                    <Input
                      maxLength={15}
                      name="code"
                      disabled={(dataid || id) && singleAgreement?.agreement_status_id !== 1}
                      placeholder=""
                      className="form-input"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <label className="form-lbl">Financer:</label>
                  <Form.Item
                    name={"user_id"}
                    rules={[
                      {
                        required: true,
                        message: "please select financer",
                      },
                    ]}
                  >
                    <Select
                      placeholder=""
                      name="user_id"
                      disabled={(dataid || id) && singleAgreement?.agreement_status_id !== 1}
                      className="form-select"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {usersData.map((data, index) => (
                        <Select.Option value={data.id} key={index}>
                          {data.first_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <label className="form-lbl">CEO:</label>
                  <Form.Item
                    name={"ceo_id"}
                    rules={[
                      {
                        required: true,
                        message: "please select CEO",
                      },
                    ]}
                  >
                    <Select
                      placeholder=""
                      name="ceo_id"
                      disabled={(dataid || id) && singleAgreement?.agreement_status_id !== 1}
                      className="form-select"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {ceoData.map((data, index) => (
                        <Select.Option value={data.id} key={index}>
                          {data.first_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <label className="form-lbl">Starting Date:</label>
                  <Form.Item
                    name={"start_date"}
                    rules={[
                      {
                        required: true,
                        message: "Starting Date can't be empty",
                      },
                    ]}
                  >
                    <DatePicker
                      disabled={(dataid || id) && singleAgreement?.agreement_status_id !== 1}
                      format="YYYY-MM-DD"
                      disabledDate={(current) =>
                        current.isBefore(moment().subtract(1, "day"))
                      }
                      className="form-input"
                      style={{ width: "100%", minHeight: 40 }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <label className="form-lbl">Ending Date:</label>
                  <Form.Item
                    name={"end_date"}
                    rules={[
                      {
                        required: true,
                        message: "Ending Date can't be empty",
                      },
                    ]}
                  >
                    <DatePicker
                      disabled={(dataid || id) && singleAgreement?.agreement_status_id !== 1}
                      format="YYYY-MM-DD"
                      disabledDate={(current) =>
                        current.isBefore(moment().subtract(1, "day"))
                      }
                      className="form-input"
                      style={{ width: "100%", minHeight: 40 }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <label className="form-lbl">Amount:</label>
                  <Form.Item
                    name={"amount"}
                    rules={[
                      {
                        required: true,
                        message: "Amount can't be empty",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      name="amount"
                      disabled={(dataid || id) && singleAgreement?.agreement_status_id !== 1}
                      placeholder=""
                      className="form-input"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <label className="form-lbl">Profit:</label>
                  <Form.Item
                    name={"profit"}
                    rules={[
                      {
                        required: true,
                        message: "Profit can't be empty",
                      },
                      // !id && { max: 2, message: "Profit is too much high" },
                    ]}
                  >
                    <Input
                      suffix="%"
                      type="number"
                      name="profit"
                      disabled={(dataid || id) && singleAgreement?.agreement_status_id !== 1}
                      placeholder=""
                      className="form-input"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <label className="form-lbl">Duration type:</label>
                  <Form.Item
                    name={"duration_type"}
                    rules={[
                      {
                        required: true,
                        message: "please select duration type",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select duration type"
                      name="duration_type"
                      disabled={(dataid || id) && singleAgreement?.agreement_status_id !== 1}
                      className="form-select"
                      onChange={(e) => {
                        setFixed(e);
                      }}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {AGREEMENT_DURATION_TYPES.map((data, index) => (
                        <Select.Option value={data.id} key={index}>
                          {data.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {isFixed === AGREEMENT_DURATION_TYPES[0].id && (
                  <Col xs={24} md={12} lg={8}>
                    <label className="form-lbl">Fixed Days:</label>
                    <Form.Item
                      name={"fixed_days"}
                      rules={[
                        {
                          required: true,
                          message: "Fixed Days can't be empty",
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        name="fixed_days"
                        disabled={(dataid || id) && singleAgreement?.agreement_status_id !== 1}
                        placeholder=""
                        className="form-input"
                      />
                    </Form.Item>
                  </Col>
                )}
                {isFixed === AGREEMENT_DURATION_TYPES[1].id && (
                  <Col xs={24} md={12} lg={8}>
                    <label className="form-lbl">Fixed Date:</label>
                    <Form.Item
                      name={"fixed_date"}
                      rules={[
                        {
                          required: true,
                          message: "Fixed Repayment Date can't be empty",
                        },
                      ]}
                    >
                      <DatePicker
                        disabled={(dataid || id) && singleAgreement?.agreement_status_id !== 1}
                        format="YYYY-MM-DD"
                        disabledDate={(current) =>
                          current.isBefore(moment().subtract(1, "day"))
                        }
                        className="form-input"
                        style={{ width: "100%", minHeight: 40 }}
                      />
                    </Form.Item>
                  </Col>
                )}
                {isFixed === AGREEMENT_DURATION_TYPES[2].id && (
                  <Col xs={24} md={12} lg={8}>
                    <label className="form-lbl">Variable Days:</label>
                    <Form.Item
                      name={"variable_days"}
                      rules={[
                        {
                          required: true,
                          message: "Variable Days can't be empty",
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        name="variable_days"
                        disabled={(dataid || id) && singleAgreement?.agreement_status_id !== 1}
                        placeholder=""
                        className="form-input"
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col
                  xs={24}
                  md={12}
                  lg={8}
                  style={{ display: paramid ? "block" : "none" }}
                >
                  <label className="form-lbl">Agreement Status:</label>
                  <Form.Item name={"agreement_status_id"}>
                    <Select
                      placeholder=""
                      name="agreement_status_id"
                      disabled={true}
                      className="form-select"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {agreementStatusData.map((data, index) => (
                        <Select.Option value={data.id} key={index}>
                          {data.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <label className="form-lbl">Principal:</label>
                  <Form.Item
                    name={"principal_id"}
                    rules={[
                      {
                        required: true,
                        message: "please select principal",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      placeholder=""
                      name="principal_id"
                      disabled={(dataid || id) && singleAgreement?.agreement_status_id !== 1}
                      className="form-select"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {principalData.map((data, index) => (
                        <Select.Option value={data.id} key={index}>
                          {data.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <label className="form-lbl">Company:</label>
                  <Form.Item
                    name={"company_id"}
                    rules={[
                      {
                        required: true,
                        message: "please select company",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      placeholder=""
                      name="company_id"
                      disabled={(dataid || id) && singleAgreement?.agreement_status_id !== 1}
                      className="form-select"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {companyData.map((data, index) => (
                        <Select.Option value={data.id} key={index}>
                          {data.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {paramid && (
                  <Col xs={24} md={12} lg={8}>
                    <label className="form-lbl">Sequence Number:</label>
                    <Form.Item name={"sequence_number"}>
                      <Input
                        type="number"
                        name="sequence_number"
                        disabled={
                          dataid ||
                          singleAgreement?.agreement_status_id === 3 ||
                          singleAgreement?.agreement_status_id === 4
                        }
                        placeholder=""
                        className="form-input"
                      />
                    </Form.Item>
                  </Col>
                )}
                {dataid && (
                  <Col xs={24} md={12} lg={8}>
                    <label className="form-lbl">Murabaha Agreement:</label>
                    {singleAgreement?.content ? (
                      <Link
                        to={AGREEMENT_CONTENT_ROUTE.replace(":id", dataid)}
                        className="preview-btn"
                      >
                        View Murabaha Agreement
                      </Link>
                    ) : (
                      <p className="preview-txt">Not Uploaded</p>
                    )}
                  </Col>
                )}
                {dataid && (
                  <Col xs={24} md={12} lg={8}>
                    <label className="form-lbl">Agency Agreement:</label>
                    {singleAgreement?.agency_content ? (
                      <Link
                        to={AGENCY_CONTENT_ROUTE.replace(":id", dataid)}
                        className="preview-btn"
                      >
                        View Agency Agreement
                      </Link>
                    ) : (
                      <p className="preview-txt">Not Uploaded</p>
                    )}
                  </Col>
                )}
                <Col xs={24} />
                {paramid && (
                  <>
                    <Col xs={24} md={12} lg={5}>
                      <label className="form-lbl">Financer Signature:</label>
                      <SignUploader
                        desc="Signature awaited from Financer"
                        text={financierSignText}
                        setText={handleSetFinancierSign}
                        image={financierSign}
                        setImage={setfinancierSign}
                        name="financier_sign"
                        agreementid={paramid}
                        isOnlyImage={dataid ? true : false}
                      />
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                      <label className="form-lbl">Company Signature:</label>
                      <SignUploader
                        desc="Signature awaited from Company"
                        text={ceoSignText}
                        setText={handleSetCeoSign}
                        image={ceoSign}
                        setImage={setCeoSign}
                        name="ceo_sign"
                        agreementid={paramid}
                        isOnlyImage={dataid ? true : false}
                      />
                    </Col>
                  </>
                )}
                <Col xs={24} md={24} lg={24}>
                  <label className="form-lbl">Attachments:</label>
                  {attachments.map((data, index) => (
                      <div className="attachments-box" key={index} onClick={() => {
                        window.open(data.replace(new RegExp('#', 'g'), '%23'), "_blank");
                      }}>
                      {!dataid &&
                      singleAgreement?.agreement_status_id !== 3 &&
                      singleAgreement?.agreement_status_id !== 4 ? (
                        <span
                          className="delete-image"
                          onClick={() => {
                            deleteAttachments(index);
                          }}
                        >
                          <DeleteOutlined />
                        </span>
                      ) : null}
                      <ImageUploader
                        image={data}
                        setImage={setAttachmentsHandler}
                        isOnlyImage
                      />
                    </div>
                  ))}
                  {!dataid &&
                  singleAgreement?.agreement_status_id !== 3 &&
                  singleAgreement?.agreement_status_id !== 4 ? (
                    <ImageUploader
                      isImage={false}
                      image={null}
                      setImage={setAttachmentsHandler}
                    />
                  ) : null}
                </Col>
                {!dataid &&
                singleAgreement?.agreement_status_id !== 0 &&
                singleAgreement?.agreement_status_id !== 0 ? (
                  <Col
                    xs={24}
                    md={24}
                    lg={24}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        disabled={isLoading}
                        className="form-btn"
                      >
                        {isLoading ? (
                          <ScaleLoader
                            color={"#fff"}
                            loading={isLoading}
                            height={14}
                            width={2}
                          />
                        ) : id ? (
                          (singleAgreement?.agreement_status_id === 1 ||
                            singleAgreement?.agreement_status_id === 2) &&
                          "Update"
                        ) : (
                          "Create"
                        )}
                      </Button>
                    </Form.Item>
                    {(singleAgreement?.agreement_status_id === 1 ||
                      singleAgreement?.agreement_status_id === 2) &&
                    id ? (
                      <span
                        onClick={() => {
                          setShowConfirmationModal(true);
                        }}
                        style={{ width: "auto", marginLeft: 10 }}
                        className="form-rm-btn"
                      >
                        {isLoading ? (
                          <ScaleLoader
                            color={"#2e3192"}
                            loading={isLoading}
                            height={14}
                            width={2}
                          />
                        ) : (
                          "Cancel Agreement"
                        )}
                      </span>
                    ) : null}
                    {(singleAgreement?.agreement_status_id === 1 ||
                      singleAgreement?.agreement_status_id === 2) &&
                    id ? (
                      <span
                        onClick={() => {
                          setCompleteShowConfirmationModal(true);
                        }}
                        style={{ width: "auto", marginLeft: 10 }}
                        className="form-rm-btn"
                      >
                        {isLoading ? (
                          <ScaleLoader
                            color={"#2e3192"}
                            loading={isLoading}
                            height={14}
                            width={2}
                          />
                        ) : (
                          "Complete Agreement"
                        )}
                      </span>
                    ) : null}
                  </Col>
                ) : null}
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
      {showConfirmationModal && (
        <ConfirmationModal
          handleClose={handleConfirmationModalClose}
          title={"Cancel Agreement?"}
          description={"Are you sure you want to cancel this agreement?"}
          deleteHandler={() => {
            handleUpdateAgreementStatus("Cancelled");
          }}
        />
      )}

      {showCompleteConfirmationModal && (
        <ConfirmationModal
          handleClose={handleCompleteConfirmationModalClose}
          title={"Complete Agreement?"}
          description={"Are you sure you want to complete this agreement?"}
          deleteHandler={() => {
            handleUpdateAgreementStatus("Completed");
          }}
        />
      )}
    </div>
  );
};

export default AgreementForm;
