import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import { errorToast } from "../utils";
import {
  ERROR_MESSAGE,
  LOCATION_DELETE_MESSAGE,
  LOCATION_UPDATE_MESSAGE,
  LOCATION_CREATE_MESSAGE,
} from "../constants";
import {
  GET_LOCATIONS,
  CREATE_LOCATIONS,
  UPDATE_LOCATIONS,
  DELETE_LOCATIONS,
  GET_SINGLE_LOCATIONS,
  callRequest,
} from "../services/webService";

export const LocationsContext = createContext();

export const LocationsContextProvider = ({ children }) => {
  const [locationsList, setLocationsList] = useState([]);
  const [singleLocation, setSingleLocation] = useState(null);
  const onFailRequest = { status: false, message: ERROR_MESSAGE };
  const getLocationsList = async (
    page = 1,
    paginateStatus = true,
    filteredQuery = "",
    responseCallback
  ) => {
    try {
      const query = `page=${page}&paginate=${paginateStatus}${filteredQuery}`;
      const res = await callRequest(GET_LOCATIONS, {}, "", query);
      if (res) {
        setLocationsList(res);
        if (responseCallback) responseCallback({ status: true });
      }
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const getSingleLocation = async (id, responseCallback) => {
    try {
      const res = await callRequest(GET_SINGLE_LOCATIONS, {}, `${id}`, "");
      if (res) {
        setSingleLocation(res);
      }
      if (responseCallback) responseCallback({ status: true });
    } catch (err) {
      console.log("err", err);
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const createLocation = async (payload, responseCallback) => {
    try {
      const res = await callRequest(CREATE_LOCATIONS, payload, "", "");
      if (res) {
        if (responseCallback) responseCallback(res);
        if (res.status === true) {
          toast.success(LOCATION_CREATE_MESSAGE);
        }
      }
      if (responseCallback)
        responseCallback({ status: false, message: res.message });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const updateLocation = async (payload, id, responseCallback) => {
    try {
      const res = await callRequest(UPDATE_LOCATIONS, payload, `${id}`, "");
      if (res) {
        if (responseCallback) responseCallback(res);
        toast.success(LOCATION_UPDATE_MESSAGE);
      }
      if (responseCallback)
        responseCallback({ status: false, message: res.message });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const deleteLocation = async (id, responseCallback) => {
    try {
      const res = await callRequest(DELETE_LOCATIONS, {}, `${id}`, "");
      if (res) {
        toast.success(LOCATION_DELETE_MESSAGE);
        getLocationsList();
        if (responseCallback) responseCallback(res);
      }
      if (responseCallback)
        responseCallback({ status: false, message: res.message });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  return (
    <LocationsContext.Provider
      value={{
        locationsList,
        singleLocation,
        getSingleLocation,
        getLocationsList,
        createLocation,
        updateLocation,
        deleteLocation,
      }}
    >
      {children}
    </LocationsContext.Provider>
  );
};
