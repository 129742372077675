import React from "react";
import SunEditor from "suneditor-react";
const ContentEditor = ({ content, onChange }) => {
  const handleContentChange = (e) => {
    onChange(e);
  };
  return (
    <div style={{ marginBottom: 15 }}>
      <SunEditor
        setContents={content}
        height={200}
        autoFocus={true}
        onChange={handleContentChange}
        setOptions={{
          buttonList: [
            [
              "undo",
              "redo",
              "bold",
              "underline",
              "italic",
              "strike",
              "list",
              "align",
              "fontSize",
              "formatBlock",
              "table",
            ],
          ],
        }}
      />
    </div>
  );
};

export default ContentEditor;
