import React, { useEffect, useState, useContext } from "react";
import { Col, Row, Tooltip, Button, Input, Form } from "antd";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import AdminDataTable from "../../Layouts/partials/data-table";
import { LookupsContext } from "../../context/LookupContext";
import { GenericContext } from "../../context/genericContext";
import { ConfirmationModal } from "../../components/Modals/index";
import { Link } from "react-router-dom";
import {
  CREATE_LOOKUP_ROUTE,
  UPDATE_LOOKUP_ROUTE,
  LOOKUP_DETAIL_ROUTE,
  PERMISSIONS,
} from "../../constants";
import { getFormattedDateTime } from "../../utils";
import FilterDrawer from "../../components/filterDrawer";
import { ScaleLoader } from "react-spinners";
import moment from "moment";
function Lookup() {
  const { lookupsList, getLookupsList, deleteLookup } =
    useContext(LookupsContext);
  const { getUserPermission, userPermissions } = useContext(GenericContext);
  const CreateAccess = PERMISSIONS.find((x) => x.route === CREATE_LOOKUP_ROUTE);
  const UpdateAccess = PERMISSIONS.find(
    (x) => x.route === UPDATE_LOOKUP_ROUTE.replace("/:id", "")
  );
  const [selectedData, setSelectedData] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState(null);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [page, setPage] = useState(1);
  const { meta, data } = lookupsList;
  const paginationConfig = {
    pageSize: meta?.per_page,
    total: meta?.total,
  };

  const [form] = Form.useForm();
  const handleFormFinish = () => {
    const credentials = form.getFieldsValue();
    setFilteredData(credentials);
    setLoading(true);
  };

  useEffect(() => {
    let filterQuery = "";
    if (filteredData) {
      Object.keys(filteredData).forEach(function (key) {
        if (filteredData[key]) {
          filterQuery += `&${key}=${filteredData[key]}`;
        }
      });
    }
    getUserPermission();
    getLookupsList(page, true, filterQuery, () => {
      setLoading(false);
      setFilterOpen(false);
    });
  }, [page, filteredData]);

  const handleResetForm = () => {
    form.resetFields();
    setFilteredData(null);
    form.setFieldsValue({
      code: undefined,
      name: undefined,
    });
    setFilterOpen(false);
  };
  const drawerSec = () => (
    <Form
      form={form}
      className="form"
      initialValues={filteredData}
      onFinish={handleFormFinish}
    >
      <label className="form-lbl">Name:</label>
      <Form.Item name={"name"}>
        <Input
          name="name"
          placeholder="Filter by lookup name"
          className="form-input"
        />
      </Form.Item>
      <label className="form-lbl">Code:</label>
      <Form.Item name={"code"}>
        <Input
          name="code"
          placeholder="Filter by lookup code"
          className="form-input"
        />
      </Form.Item>
      <Form.Item>
        <Button
          htmlType="submit"
          disabled={isLoading}
          className="form-btn"
          style={{ minWidth: "100%" }}
        >
          {isLoading ? (
            <ScaleLoader
              color={"#FFF"}
              loading={isLoading}
              height={14}
              width={2}
            />
          ) : (
            "Apply Filters"
          )}
        </Button>
        <span className="form-rm-btn" onClick={handleResetForm}>
          Reset Filters
        </span>
      </Form.Item>
    </Form>
  );

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "1",
      render: (data, value, i) => (
        <b>{(page == 1 ? 0 : (page - 1) * 10) + (i + 1)}</b>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "2",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (data, value) => {
        return <span>{data}</span>;
      },
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "3",
      sorter: (a, b) => a.code.localeCompare(b.code),
      render: (data, value) => {
        return <span>{data}</span>;
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "4",
      sorter: (a, b) => a.type.localeCompare(b.type),
      render: (data, value) => {
        return <span>{data}</span>;
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "5",
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
      render: (data, value) => {
        return <span>{getFormattedDateTime(data)}</span>;
      },
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "6",
      sorter: (a, b) =>
        moment(a.updated_at).unix() - moment(b.updated_at).unix(),
      render: (data, value) => {
        return <span>{getFormattedDateTime(data)}</span>;
      },
    },
    {
      title: "Action",
      key: "7",
      render: (data, record) => {
        return (
          <div className="actions">
            <Link
              to={LOOKUP_DETAIL_ROUTE.replace(":dataid", data.id)}
              className="update"
            >
              <Tooltip title="Lookup Detail">
                <EyeOutlined />
              </Tooltip>
            </Link>
            {userPermissions.includes(UpdateAccess.access) && (
              <Link
                to={UPDATE_LOOKUP_ROUTE.replace(":id", data.id)}
                className="update"
              >
                <Tooltip title="Update lookup">
                  <EditOutlined />
                </Tooltip>
              </Link>
            )}
            {/* <span style={{ opacity: data.is_default ? 0.3 : 1 }}>
              {data.is_default ? (
                <DeleteOutlined style={{ cursor: "auto" }} />
              ) : (
                <Tooltip title="Delete Lookup">
                  <DeleteOutlined onClick={() => handleConfirmModal(record)} />
                </Tooltip>
              )}
            </span> */}
          </div>
        );
      },
    },
  ];

  //HANDLERS

  const handleConfirmModal = (role) => {
    setSelectedData(role);
    setShowConfirmationModal(true);
  };
  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleDeleteRole = (id) => {
    deleteLookup(id);
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };

  const filterHandler = () => {
    setFilterOpen(!isFilterOpen);
  };

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            {userPermissions.includes(CreateAccess.access) && (
              <div className="add-btn">
                <Link to={CREATE_LOOKUP_ROUTE}>Create Lookup</Link>
              </div>
            )}
            <AdminDataTable
              title="Lookups"
              filterHandler={filterHandler}
              data={data}
              columns={columns}
              pagination={{ ...paginationConfig, onChange: handlePageChange }}
            />
          </Col>
        </Row>
      </div>
      {showConfirmationModal && (
        <ConfirmationModal
          handleClose={handleConfirmationModalClose}
          title={"Delete Lookup?"}
          description={"Are you sure you want to delete this lookup?"}
          deleteHandler={handleDeleteRole}
          selectedData={selectedData}
        />
      )}
      <FilterDrawer
        visible={isFilterOpen}
        toggle={filterHandler}
        content={drawerSec()}
      />
    </>
  );
}

export default Lookup;
