import React, { useContext, useState, useEffect } from "react";
import { Row, Col, Card, Form, Input, Button, Checkbox, Select } from "antd";
import { LocationsContext } from "../../context/LocationContext";
import { PrincipalsContext } from "../../context/PrincipalContext";
import { GenericContext } from "../../context/genericContext";
import { useHistory, useParams } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Link } from "react-router-dom";
import {
  PRINCIPALS_ROUTE,
  UPDATE_PRINCIPALS_ROUTE,
  PERMISSIONS,
} from "../../constants";
import { toast } from "react-toastify";
const PrincipalForm = () => {
  const { id, dataid } = useParams();
  const history = useHistory();
  const paramid = id || dataid;
  const [isLoading, setLoading] = useState(false);
  const [isActivated, setActivated] = useState(true);
  const [locationData, setLocationData] = useState([]);
  const { locationsList, getLocationsList } = useContext(LocationsContext);
  const {
    singlePrincipal,
    getSinglePrincipal,
    createPrincipal,
    updatePrincipal,
  } = useContext(PrincipalsContext);
  const { getUserPermission, userPermissions } = useContext(GenericContext);
  const UpdateAccess = PERMISSIONS.find(
    (x) => x.route === UPDATE_PRINCIPALS_ROUTE.replace("/:id", "")
  );
  const [form] = Form.useForm();
  const handleFormFinish = () => {
    const credentials = form.getFieldsValue();
    credentials["is_active"] = isActivated;
    credentials["location_ids"] = credentials.location_ids.join(',')
    setLoading(true);
    if (id) {
      updatePrincipal(credentials, id, (res) => {
        setLoading(false);
        if (res.status) {
          history.push(PRINCIPALS_ROUTE);
        }
      });
    } else {
      createPrincipal(credentials, (res) => {
        setLoading(false);
        if (res.status) {
          history.push(PRINCIPALS_ROUTE);
        } else {
          if (res.message.errors) {
            toast.error(res.message.errors[0].message);
          }
        }
      });
    }
  };
  useEffect(() => {
    if (paramid) {
      getSinglePrincipal(paramid);
    }
    getUserPermission();
    getLocationsList(1, false, "");
  }, []);

  useEffect(() => {
    setLocationData(
      Array.isArray(locationsList) ? locationsList : locationsList.data
    );
  }, [locationsList]);
  useEffect(() => {
    if (singlePrincipal && paramid) {
      form.setFieldsValue({
        name: singlePrincipal.name,
        code: singlePrincipal.code,
        location_ids: singlePrincipal.locations.map((x) => {
          return x.id;
        }),
      });
      setActivated(singlePrincipal.is_active);
    }
  }, [singlePrincipal]);
  return (
    <div className="layout-content">
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          {userPermissions.includes(UpdateAccess.access) && (
            <>
              {dataid && (
                <div style={{ textAlign: "right" }}>
                  <Link to={UPDATE_PRINCIPALS_ROUTE.replace(":id", paramid)}>
                    <Button className="filter-btn">Edit</Button>
                  </Link>
                </div>
              )}
            </>
          )}
          <Card
            bordered={false}
            className="criclebox cardbody "
            style={{ padding: "20px 40px" }}
          >
            <Form form={form} className="form" onFinish={handleFormFinish}>
              <Row gutter={[24, 0]}>
                <Col xs={24} md={12} lg={8}>
                  <label className="form-lbl">Name:</label>
                  <Form.Item
                    name={"name"}
                    rules={[
                      {
                        required: true,
                        message: "Name can't be empty",
                      },
                    ]}
                  >
                    <Input
                      name="name"
                      disabled={dataid}
                      placeholder=""
                      className="form-input"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <label className="form-lbl">Code:</label>
                  <Form.Item
                    name={"code"}
                    rules={[
                      {
                        required: true,
                        message: "Code can't be empty",
                      },
                    ]}
                  >
                    <Input
                      name="code"
                      disabled={dataid}
                      placeholder=""
                      className="form-input"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <label className="form-lbl">Location:</label>
                  <Form.Item
                    name={"location_ids"}
                    rules={[
                      {
                        required: true,
                        message: "please select locations",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      placeholder=""
                      name="location_ids"
                      // disabled={dataid}
                      className="form-select"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {locationData.map((data, index) => (
                        <Select.Option value={data.id} key={index}>
                          {data.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  md={12}
                  lg={8}
                  style={{ display: "flex", alignItems: "flex-end" }}
                >
                  <Form.Item name={"is_active"}>
                    <Checkbox
                      name="is_active"
                      defaultChecked={isActivated}
                      onChange={() => {
                        setActivated(!isActivated);
                      }}
                      disabled={dataid}
                    />
                    <label className="form-sublbl">Is Active?</label>
                  </Form.Item>
                </Col>
                {!dataid && (
                  <Col xs={24} md={24} lg={24}>
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        disabled={isLoading}
                        className="form-btn"
                      >
                        {isLoading ? (
                          <ScaleLoader
                            color={"#FFF"}
                            loading={isLoading}
                            height={14}
                            width={2}
                          />
                        ) : id ? (
                          "Update"
                        ) : (
                          "Create"
                        )}
                      </Button>
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default PrincipalForm;
