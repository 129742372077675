import {
  faUserTag,
  faUsers,
  faBalanceScaleLeft,
  faBuilding,
  faMapMarkerAlt,
  faFileContract,
  faBookOpen,
  faChartLine,
  faExchangeAlt,
} from "@fortawesome/free-solid-svg-icons";

export const ERROR_MESSAGE = "Something went wrong";
export const LOGIN_MESSAGE = "Login Sucessfully";
export const LOGOUT_MESSAGE = "Logout Sucessfully";
export const ROLE_CREATE_MESSAGE = "Role Created Sucessfully";
export const ROLE_UPDATE_MESSAGE = "Role Updated Sucessfully";
export const ROLE_DELETE_MESSAGE = "Role Deleted Sucessfully";
export const LOOKUP_CREATE_MESSAGE = "Lookup Created Sucessfully";
export const LOOKUP_UPDATE_MESSAGE = "Lookup Updated Sucessfully";
export const LOOKUP_DELETE_MESSAGE = "Lookup Deleted Sucessfully";
export const LOCATION_CREATE_MESSAGE = "Location Created Sucessfully";
export const LOCATION_UPDATE_MESSAGE = "Location Updated Sucessfully";
export const LOCATION_DELETE_MESSAGE = "Location Deleted Sucessfully";
export const PRINCIPAL_CREATE_MESSAGE = "Principal Created Sucessfully";
export const PRINCIPAL_UPDATE_MESSAGE = "Principal Updated Sucessfully";
export const PRINCIPAL_DELETE_MESSAGE = "Principal Deleted Sucessfully";
export const COMPANY_CREATE_MESSAGE = "Company Created Sucessfully";
export const COMPANY_UPDATE_MESSAGE = "Company Updated Sucessfully";
export const COMPANY_DELETE_MESSAGE = "Company Deleted Sucessfully";
export const USER_CREATE_MESSAGE = "User Created Sucessfully";
export const USER_UPDATE_MESSAGE = "User Updated Sucessfully";
export const USER_DELETE_MESSAGE = "User Deleted Sucessfully";
export const AGREEMENT_CREATE_MESSAGE = "Agreement Created Sucessfully";
export const AGREEMENT_UPDATE_MESSAGE = "Agreement Updated Sucessfully";
export const AGREEMENT_DELETE_MESSAGE = "Agreement Deleted Sucessfully";
export const TRANSACTION_CANCELLED = "Transaction Cancelled Sucessfully";
// LOGIN ROUTE
export const LOGIN_ROUTE = "/";
// DASHBOARD ROUTE
export const DASHBOARD_ROUTE = "/dashboard";
// USERS ROUTES
export const USERS_ROUTE = "/users";
export const CREATE_USERS_ROUTE = "/create-user";
export const UPDATE_USERS_ROUTE = "/update-user/:id";
export const USERS_DETAIL_ROUTE = "/user-detail/:dataid";
// ROLES ROUTES
export const ROLES_ROUTE = "/roles";
export const CREATE_ROLES_ROUTE = "/create-role";
export const UPDATE_ROLES_ROUTE = "/update-role/:id";
export const ROLES_DETAIL_ROUTE = "/role-detail/:dataid";
// PRINCIPALS ROUTES
export const PRINCIPALS_ROUTE = "/principals";
export const CREATE_PRINCIPALS_ROUTE = "/create-principal";
export const UPDATE_PRINCIPALS_ROUTE = "/update-principal/:id";
export const PRINCIPALS_DETAIL_ROUTE = "/principal-detail/:dataid";
// COMPANY ROUTES
export const COMPANY_ROUTE = "/companies";
export const CREATE_COMPANY_ROUTE = "/create-company";
export const UPDATE_COMPANY_ROUTE = "/update-company/:id";
export const COMPANY_DETAIL_ROUTE = "/company-detail/:dataid";
// LOCATION ROUTES
export const LOCATION_ROUTE = "/locations";
export const CREATE_LOCATION_ROUTE = "/create-location";
export const UPDATE_LOCATION_ROUTE = "/update-location/:id";
export const LOCATION_DETAIL_ROUTE = "/location-detail/:dataid";
// AGREEMENT ROUTES
export const AGREEMENT_ROUTE = "/agreements";
export const CREATE_AGREEMENT_ROUTE = "/create-agreement";
export const UPDATE_AGREEMENT_ROUTE = "/update-agreement/:id";
export const AGREEMENT_DETAIL_ROUTE = "/agreement-detail/:dataid";
export const AGREEMENT_CONTENT_ROUTE = "/agreement-content/:id";
export const AGENCY_CONTENT_ROUTE = "/agency-content/:id";
// LOOKUP ROUTES
export const LOOKUP_ROUTE = "/lookups";
export const CREATE_LOOKUP_ROUTE = "/create-lookup";
export const UPDATE_LOOKUP_ROUTE = "/update-lookup/:id";
export const LOOKUP_DETAIL_ROUTE = "/lookup-detail/:dataid";
// TRANSACTION ROUTES
export const TRANSACTION_ROUTE = "/transactions";
export const TRANSACTION_DETAIL_ROUTE = "/transaction-detail/:dataid";
// REPORT ROUTES
export const REPORT_ROUTE = "/reports";
export const CREATE_REPORT_ROUTE = "/create-report";
export const UPDATE_REPORT_ROUTE = "/update-report/:id";
export const REPORT_DETAIL_ROUTE = "/report-detail/:dataid";

export const SIDEBAR_LINKS = [
  {
    title: "Dashboard",
    icon: faChartLine,
    route: DASHBOARD_ROUTE,
    access: 0,
  },
  {
    title: "Users",
    icon: faUsers,
    route: USERS_ROUTE,
    access: 1,
  },
  {
    title: "Roles",
    icon: faUserTag,
    route: ROLES_ROUTE,
    access: 5,
  },
  {
    title: "Principals",
    icon: faBalanceScaleLeft,
    route: PRINCIPALS_ROUTE,
    access: 10,
  },
  {
    title: "Companies",
    icon: faBuilding,
    route: COMPANY_ROUTE,
    access: 14,
  },
  {
    title: "Locations",
    icon: faMapMarkerAlt,
    route: LOCATION_ROUTE,
    access: 18,
  },
  {
    title: "Agreements",
    icon: faFileContract,
    route: AGREEMENT_ROUTE,
    access: 22,
  },
  {
    title: "Lookups",
    icon: faBookOpen,
    route: LOOKUP_ROUTE,
    access: 30,
  },
  {
    title: "Transactions",
    icon: faExchangeAlt,
    route: TRANSACTION_ROUTE,
    access: 26,
  },
  // {
  //   title: "Reports",
  //   icon: faFileAlt,
  //   route: REPORT_ROUTE,
  //   access:38
  // },
];
export const DASHBOARD_ANALYTICS = [
  {
    id: "users",
    title: "Users",
    icon: faUsers,
    access: 1,
  },
  {
    id: "roles",
    title: "Roles",
    icon: faUserTag,
    access: 5,
  },
  {
    id: "principals",
    title: "Principals",
    icon: faBalanceScaleLeft,
    access: 10,
  },
  {
    id: "companies",
    title: "Companies",
    icon: faBuilding,
    access: 14,
  },
  {
    id: "locations",
    title: "Locations",
    icon: faMapMarkerAlt,
    access: 18,
  },
  {
    id: "agreements",
    title: "Agreements",
    icon: faFileContract,
    access: 22,
  },
];
export const LOOKUP_TYPES = ["location", "system", "email"];

export const AGREEMENT_DURATION_TYPES = [
  { id: "fixed_days", name: "fixed days" },
  { id: "fixed_date", name: "Fixed Repayment Date" },
  { id: "variable_days", name: "Variable Days" },
];

export const TRANSACTION_STATUS = [
  { id: "in progress", name: "In progress" },
  { id: "hold", name: "Hold" },
  { id: "approved", name: "Approved" },
  { id: "rejected", name: "Rejected" },
  { id: "completed", name: "Completed" },
  { id: "rejected by auditor", name: "Rejected By Auditor" },
  { id: "pending for auditor", name: "Pending For Auditor" },
];
export const TRANSACTION_CHECKLIST = [
  { id: "location_image", name: "Offloading Location" },
  { id: "identity_image", name: "Stock Identity" },
  { id: "truck_seal_image", name: "Truck Seal Open" },
];
export const STATUS_FILTER = [
  { id: "1", name: "Active" },
  { id: "0", name: "Inactive" },
];
export const PERMISSIONS = [
  {
    route: DASHBOARD_ROUTE,
    access: "all",
  },
  {
    route: USERS_ROUTE,
    access: 1,
  },
  {
    route: USERS_DETAIL_ROUTE.replace("/:dataid", ""),
    access: 1,
  },
  {
    route: CREATE_USERS_ROUTE,
    access: 2,
  },
  {
    route: UPDATE_USERS_ROUTE.replace("/:id", ""),
    access: 3,
  },
  {
    route: "delete-user",
    access: 4,
  },
  {
    route: ROLES_ROUTE,
    access: 5,
  },
  {
    route: ROLES_DETAIL_ROUTE.replace("/:dataid", ""),
    access: 5,
  },
  {
    route: CREATE_ROLES_ROUTE,
    access: 6,
  },
  {
    route: UPDATE_ROLES_ROUTE.replace("/:id", ""),
    access: 7,
  },
  {
    route: "delete-role",
    access: 8,
  },
  {
    route: "roles-permission",
    access: 9,
  },
  {
    route: PRINCIPALS_ROUTE,
    access: 10,
  },
  {
    route: PRINCIPALS_DETAIL_ROUTE.replace("/:dataid", ""),
    access: 10,
  },
  {
    route: CREATE_PRINCIPALS_ROUTE,
    access: 11,
  },
  {
    route: UPDATE_PRINCIPALS_ROUTE.replace("/:id", ""),
    access: 12,
  },
  {
    route: "delete-principal",
    access: 13,
  },
  {
    route: COMPANY_ROUTE,
    access: 14,
  },
  {
    route: COMPANY_DETAIL_ROUTE.replace("/:dataid", ""),
    access: 14,
  },
  {
    route: CREATE_COMPANY_ROUTE,
    access: 15,
  },
  {
    route: UPDATE_COMPANY_ROUTE.replace("/:id", ""),
    access: 16,
  },
  {
    route: "delete-company",
    access: 17,
  },
  {
    route: LOCATION_ROUTE,
    access: 18,
  },
  {
    route: LOCATION_DETAIL_ROUTE.replace("/:dataid", ""),
    access: 18,
  },
  {
    route: CREATE_LOCATION_ROUTE,
    access: 19,
  },
  {
    route: UPDATE_LOCATION_ROUTE.replace("/:id", ""),
    access: 20,
  },
  {
    route: "delete-location",
    access: 21,
  },
  {
    route: AGREEMENT_ROUTE,
    access: 22,
  },
  {
    route: AGREEMENT_DETAIL_ROUTE.replace("/:dataid", ""),
    access: 22,
  },
  {
    route: AGREEMENT_CONTENT_ROUTE.replace("/:id", ""),
    access: 22,
  },
  {
    route: AGENCY_CONTENT_ROUTE.replace("/:id", ""),
    access: 22,
  },
  {
    route: CREATE_AGREEMENT_ROUTE,
    access: 23,
  },
  {
    route: UPDATE_AGREEMENT_ROUTE.replace("/:id", ""),
    access: 24,
  },
  {
    route: "delete-agreement",
    access: 25,
  },
  {
    route: TRANSACTION_ROUTE,
    access: 26,
  },
  {
    route: TRANSACTION_DETAIL_ROUTE.replace("/:dataid", ""),
    access: 26,
  },
  {
    route: "delete-transaction",
    access: 29,
  },
  {
    route: LOOKUP_ROUTE,
    access: 30,
  },
  {
    route: LOOKUP_DETAIL_ROUTE.replace("/:dataid", ""),
    access: 30,
  },
  {
    route: CREATE_LOOKUP_ROUTE,
    access: 31,
  },
  {
    route: UPDATE_LOOKUP_ROUTE.replace("/:id", ""),
    access: 32,
  },
  {
    route: "delete-lookup",
    access: 33,
  },
  {
    route: REPORT_ROUTE,
    access: 34,
  },
  {
    route: REPORT_DETAIL_ROUTE.replace("/:dataid", ""),
    access: 34,
  },
];
