import React from "react";
import { Row, Col, Checkbox } from "antd";
const RolePrivilege = ({ priveleges, rolePriveleges, setRolePrivelege }) => {
  return (
    <div className="privilege-content">
      <Row gutter={[24, 0]}>
        {priveleges.map((data, index) => (
          <React.Fragment key={index}>
            <Col xs={24} style={{ marginTop: 10 }}>
              <label
                className="form-lbl"
                style={{ paddingBottom: 0, display: "block" }}
              >
                {data.name}
              </label>
            </Col>
            {data.permissions.map((item, i) => (
              <Col
                xs={12}
                md={6}
                lg={6}
                key={i}
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  paddingBottom: 10,
                }}
              >
                <Checkbox
                  name={item.name}
                  defaultChecked={rolePriveleges.includes(item.id)}
                  onChange={() => {
                    setRolePrivelege(item.id);
                  }}
                />
                <label className="form-sublbl">{item.name}</label>
              </Col>
            ))}
          </React.Fragment>
        ))}
      </Row>
    </div>
  );
};

export default RolePrivilege;
