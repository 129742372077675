import React, { useEffect, useState, useContext } from "react";
import {
  Col,
  Row,
  Tooltip,
  Form,
  Input,
  Button,
  Select,
  DatePicker,
} from "antd";
import moment from "moment";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import AdminDataTable from "../../Layouts/partials/data-table";
import { UsersContext } from "../../context/UsersContext";
import { RolesContext } from "../../context/RolesContext";
import { LocationsContext } from "../../context/LocationContext";
import { GenericContext } from "../../context/genericContext";
import { ConfirmationModal } from "../../components/Modals/index";
import { Link } from "react-router-dom";
import {
  CREATE_USERS_ROUTE,
  UPDATE_USERS_ROUTE,
  USERS_DETAIL_ROUTE,
  PERMISSIONS,
  STATUS_FILTER,
} from "../../constants";
import { getFormattedDateTime, getFormattedDate } from "../../utils";
import FilterDrawer from "../../components/filterDrawer";
import { userListMauplator } from "../../excelDataManuplator/users";
import { ScaleLoader } from "react-spinners";

function Users() {
  const { RangePicker } = DatePicker;
  const { usersList, getUsersList, deleteUser } = useContext(UsersContext);
  const { rolesList, getRolesList } = useContext(RolesContext);
  const { getUserPermission, userPermissions } = useContext(GenericContext);
  const CreateAccess = PERMISSIONS.find((x) => x.route === CREATE_USERS_ROUTE);
  const UpdateAccess = PERMISSIONS.find(
    (x) => x.route === UPDATE_USERS_ROUTE.replace("/:id", "")
  );
  const { locationsList, getLocationsList } = useContext(LocationsContext);
  const [locationData, setLocationData] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState({
    is_active: "1",
  });
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [page, setPage] = useState(1);
  const { meta, data } = usersList;
  const paginationConfig = {
    pageSize: meta?.per_page,
    total: meta?.total,
  };

  const [form] = Form.useForm();
  const handleFormFinish = () => {
    const credentials = form.getFieldsValue();
    if (Array.isArray(credentials.date)) {
      credentials["start_date"] = getFormattedDate(credentials.date[0]);
      credentials["end_date"] = getFormattedDate(credentials.date[1]);
    }
    delete credentials["date"];
    setFilteredData(credentials);
    setLoading(true);
  };

  useEffect(() => {
    let filterQuery = "";
    if (filteredData) {
      Object.keys(filteredData).forEach(function (key) {
        if (filteredData[key]) {
          filterQuery += `&${key}=${filteredData[key]}`;
        }
      });
    }
    getUserPermission();
    getUsersList(page, true, filterQuery, () => {
      setLoading(false);
      setFilterOpen(false);
    });
  }, [page, filteredData]);

  useEffect(() => {
    getLocationsList(1, false, "");
    getRolesList(1, false, "");
  }, []);

  useEffect(() => {
    setRolesData(Array.isArray(rolesList) ? rolesList : rolesList.data);
  }, [rolesList]);

  useEffect(() => {
    setLocationData(Array.isArray(locationsList) ? locationsList : locationsList.data);
  }, [locationsList]);

  const handleResetForm = () => {
    form.resetFields();
    setFilteredData(null);
    form.setFieldsValue({
      name: undefined,
      role_id: undefined,
      is_active: undefined,
      date: undefined,
    });
    setFilterOpen(false);
  };
  const drawerSec = () => (
    <Form
      form={form}
      className="form"
      initialValues={filteredData}
      onFinish={handleFormFinish}
    >
      <label className="form-lbl">Name:</label>
      <Form.Item name={"name"}>
        <Input
          name="name"
          placeholder="Filter by user name"
          className="form-input"
        />
      </Form.Item>
      <label className="form-lbl">Status:</label>
      <Form.Item name={"is_active"}>
        <Select
          placeholder="Filter by user status"
          name="is_active"
          className="form-select"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {STATUS_FILTER.map((data, index) => (
            <Select.Option value={data.id} key={index}>
              {data.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <label className="form-lbl">Role:</label>
      <Form.Item name={"role_id"}>
        <Select
          placeholder="Filter by user role"
          name="role_id"
          className="form-select"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {rolesData.map((data, index) => (
            <Select.Option value={data.id} key={index}>
              {data.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <label className="form-lbl">Location:</label>
      <Form.Item name={"location_id"}>
        <Select
          placeholder="Filter by location"
          name="location_id"
          className="form-select"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {locationData.map((data, index) => (
            <Select.Option value={data.id} key={index}>
              {data.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <label className="form-lbl">Expiry Date:</label>
      <Form.Item name={"expiry_date"}>
        <DatePicker
          format="YYYY-MM-DD"
          disabledDate={(current) =>
            current.isBefore(moment().subtract(1, "day"))
          }
          className="form-input"
        />
      </Form.Item>
      <label className="form-lbl">Created Date:</label>
      <Form.Item name={"date"}>
        <RangePicker className="form-input" />
      </Form.Item>
      <Form.Item>
        <Button
          htmlType="submit"
          disabled={isLoading}
          className="form-btn"
          style={{ minWidth: "100%" }}
        >
          {isLoading ? (
            <ScaleLoader
              color={"#FFF"}
              loading={isLoading}
              height={14}
              width={2}
            />
          ) : (
            "Apply Filters"
          )}
        </Button>
        <span className="form-rm-btn" onClick={handleResetForm}>
          Reset Filters
        </span>
      </Form.Item>
    </Form>
  );

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "1",
      render: (data, value, i) => (
        <b>{(page == 1 ? 0 : (page - 1) * 10) + (i + 1)}</b>
      ),
    },
    {
      title: "Name",
      dataIndex: "",
      key: "2",
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      render: (data, value) => {
        return (
          <span>
            {data?.first_name} {data?.last_name}
          </span>
        );
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "3",
      sorter: (a, b) => a.role?.name.localeCompare(b.role?.name),
      render: (data, value) => {
        return <span>{data?.name}</span>;
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "4",
      sorter: (a, b) => a.location?.name.localeCompare(b.location?.name),
      render: (data, value) => {
        return <span>{data?.name}</span>;
      },
    },
    {
      title: "Expiry Date",
      dataIndex: "expiry_date",
      key: "5",
      sorter: (a, b) =>
        moment(a.expiry_date).unix() - moment(b.expiry_date).unix(),
      render: (data, value) => {
        return <span>{getFormattedDateTime(data)}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "6",
      sorter: (a, b) => a.is_active - b.is_active,
      render: (data, value) => {
        return <span>{data === 1 ? "Active" : "Inactive"}</span>;
      },
    },
    {
      title: "Created By",
      dataIndex: "creator",
      key: "8",
      sorter: (a, b) => a.creator?.email.localeCompare(a.creator?.email),
      render: (data, value) => {
        return <span>{data.email}</span>;
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "8",
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
      render: (data, value) => {
        return <span>{getFormattedDateTime(data)}</span>;
      },
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "9",
      sorter: (a, b) =>
        moment(a.updated_at).unix() - moment(b.updated_at).unix(),
      render: (data, value) => {
        return <span>{getFormattedDateTime(data)}</span>;
      },
    },
    {
      title: "Action",
      key: "10",
      render: (data, record) => {
        return (
          <div className="actions">
            <Link
              to={USERS_DETAIL_ROUTE.replace(":dataid", data.id)}
              className="update"
            >
              <Tooltip title="User Detail">
                <EyeOutlined />
              </Tooltip>
            </Link>
            {userPermissions.includes(UpdateAccess.access) && (
              <Link
                to={UPDATE_USERS_ROUTE.replace(":id", data.id)}
                className="update"
              >
                <Tooltip title="Update User">
                  <EditOutlined />
                </Tooltip>
              </Link>
            )}
            {/* <span style={{ opacity: data.is_default ? 0.3 : 1 }}>
              {data.is_default ? (
                <DeleteOutlined style={{ cursor: "auto" }} />
              ) : (
                <Tooltip title="Delete User">
                  <DeleteOutlined onClick={() => handleConfirmModal(record)} />
                </Tooltip>
              )}
            </span> */}
          </div>
        );
      },
    },
  ];

  //HANDLERS

  const handleConfirmModal = (role) => {
    setSelectedData(role);
    setShowConfirmationModal(true);
  };
  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleDeleteRole = (id) => {
    deleteUser(id);
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };

  const filterHandler = () => {
    setFilterOpen(!isFilterOpen);
  };
  const exportExcelData = () => {
    return userListMauplator(data);
  };
  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            {userPermissions.includes(CreateAccess.access) && (
              <div className="add-btn">
                <Link to={CREATE_USERS_ROUTE}>Create User</Link>
              </div>
            )}
            <AdminDataTable
              title="Users"
              filterHandler={filterHandler}
              data={data}
              columns={columns}
              handleGetAllFunc={() => {
                setFilteredData({ limit: 99 * 99 });
              }}
              sheet="users"
              exportFunc={exportExcelData}
              pagination={{ ...paginationConfig, onChange: handlePageChange }}
            />
          </Col>
        </Row>
      </div>
      {showConfirmationModal && (
        <ConfirmationModal
          handleClose={handleConfirmationModalClose}
          title={"Delete User?"}
          description={"Are you sure you want to delete this user?"}
          deleteHandler={handleDeleteRole}
          selectedData={selectedData}
        />
      )}
      <FilterDrawer
        visible={isFilterOpen}
        toggle={filterHandler}
        content={drawerSec()}
      />
    </>
  );
}

export default Users;
