import React from "react";
import {
  AuthContextProvider,
  RolesContextProvider,
  GenericContextProvider,
  LookupsContextProvider,
  LocationsContextProvider,
  PrincipalsContextProvider,
  UsersContextProvider,
  CompanyContextProvider,
  AgreementContextProvider,
  TransactionsContextProvider,
} from "../context";
export default function ContextWrapper({ children }) {
  return (
    <React.StrictMode>
      <AuthContextProvider>
        <RolesContextProvider>
          <LookupsContextProvider>
            <LocationsContextProvider>
              <PrincipalsContextProvider>
                <UsersContextProvider>
                  <CompanyContextProvider>
                    <AgreementContextProvider>
                      <GenericContextProvider>
                        <TransactionsContextProvider>
                          {children}
                        </TransactionsContextProvider>
                      </GenericContextProvider>
                    </AgreementContextProvider>
                  </CompanyContextProvider>
                </UsersContextProvider>
              </PrincipalsContextProvider>
            </LocationsContextProvider>
          </LookupsContextProvider>
        </RolesContextProvider>
      </AuthContextProvider>
    </React.StrictMode>
  );
}
