import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ErrorPage from "../components/404/index.jsx";
import {
  LOGIN_ROUTE,
  DASHBOARD_ROUTE,
  USERS_ROUTE,
  CREATE_USERS_ROUTE,
  UPDATE_USERS_ROUTE,
  USERS_DETAIL_ROUTE,
  ROLES_ROUTE,
  CREATE_ROLES_ROUTE,
  UPDATE_ROLES_ROUTE,
  ROLES_DETAIL_ROUTE,
  PRINCIPALS_ROUTE,
  CREATE_PRINCIPALS_ROUTE,
  UPDATE_PRINCIPALS_ROUTE,
  PRINCIPALS_DETAIL_ROUTE,
  AGREEMENT_ROUTE,
  CREATE_AGREEMENT_ROUTE,
  UPDATE_AGREEMENT_ROUTE,
  AGREEMENT_DETAIL_ROUTE,
  COMPANY_ROUTE,
  CREATE_COMPANY_ROUTE,
  UPDATE_COMPANY_ROUTE,
  COMPANY_DETAIL_ROUTE,
  LOCATION_ROUTE,
  CREATE_LOCATION_ROUTE,
  UPDATE_LOCATION_ROUTE,
  LOCATION_DETAIL_ROUTE,
  LOOKUP_ROUTE,
  CREATE_LOOKUP_ROUTE,
  UPDATE_LOOKUP_ROUTE,
  LOOKUP_DETAIL_ROUTE,
  REPORT_ROUTE,
  CREATE_REPORT_ROUTE,
  UPDATE_REPORT_ROUTE,
  REPORT_DETAIL_ROUTE,
  TRANSACTION_ROUTE,
  TRANSACTION_DETAIL_ROUTE,
  AGREEMENT_CONTENT_ROUTE,
  AGENCY_CONTENT_ROUTE,
} from "../constants/index.js";
// PRIVATE ROUTES
import AdminPrivateRoute from "../utils/privateRoute";
import {
  AdminDashboard,
  RoleManagement,
  RoleForm,
  Comapny,
  CompanyForm,
  Agreements,
  AgreementsForm,
  Location,
  LocationForm,
  Lookup,
  LookupForm,
  Principals,
  PrincipalsForm,
  Reports,
  ReportsForm,
  Users,
  UsersForm,
  SignIn,
  Transactions,
  TransactionForm,
  AgreementContentViewer,
  AgencyContentViewer,
} from "../modules";

const Routes = () => {
  return (
    <>
      <Router>
        <ToastContainer autoClose={3000} />
        <Switch>
          {/* LOGIN ROUTES */}
          <Route exact path={LOGIN_ROUTE} component={SignIn} />
          {/* DASHBOARD ROUTES */}
          <AdminPrivateRoute
            exact
            path={DASHBOARD_ROUTE}
            component={AdminDashboard}
          />
          {/* ROLES ROUTES */}
          <AdminPrivateRoute
            exact
            path={ROLES_ROUTE}
            component={RoleManagement}
          />
          <AdminPrivateRoute
            exact
            path={CREATE_ROLES_ROUTE}
            component={RoleForm}
          />
          <AdminPrivateRoute
            exact
            path={UPDATE_ROLES_ROUTE}
            component={RoleForm}
          />
          <AdminPrivateRoute
            exact
            path={ROLES_DETAIL_ROUTE}
            component={RoleForm}
          />
          {/* USERS ROUTES */}
          <AdminPrivateRoute exact path={USERS_ROUTE} component={Users} />
          <AdminPrivateRoute
            exact
            path={CREATE_USERS_ROUTE}
            component={UsersForm}
          />
          <AdminPrivateRoute
            exact
            path={UPDATE_USERS_ROUTE}
            component={UsersForm}
          />
          <AdminPrivateRoute
            exact
            path={USERS_DETAIL_ROUTE}
            component={UsersForm}
          />
          {/* PRINCIPALS ROUTES */}
          <AdminPrivateRoute
            exact
            path={PRINCIPALS_ROUTE}
            component={Principals}
          />
          <AdminPrivateRoute
            exact
            path={CREATE_PRINCIPALS_ROUTE}
            component={PrincipalsForm}
          />
          <AdminPrivateRoute
            exact
            path={UPDATE_PRINCIPALS_ROUTE}
            component={PrincipalsForm}
          />
          <AdminPrivateRoute
            exact
            path={PRINCIPALS_DETAIL_ROUTE}
            component={PrincipalsForm}
          />
          {/* AGREEMENT ROUTES */}
          <AdminPrivateRoute
            exact
            path={AGREEMENT_ROUTE}
            component={Agreements}
          />
          <AdminPrivateRoute
            exact
            path={CREATE_AGREEMENT_ROUTE}
            component={AgreementsForm}
          />
          <AdminPrivateRoute
            exact
            path={UPDATE_AGREEMENT_ROUTE}
            component={AgreementsForm}
          />
          <AdminPrivateRoute
            exact
            path={AGREEMENT_DETAIL_ROUTE}
            component={AgreementsForm}
          />
          <AdminPrivateRoute
            exact
            noLayout
            path={AGREEMENT_CONTENT_ROUTE}
            component={AgreementContentViewer}
          />
          <AdminPrivateRoute
            exact
            noLayout
            path={AGENCY_CONTENT_ROUTE}
            component={AgencyContentViewer}
          />
          {/* COMPANY ROUTES */}
          <AdminPrivateRoute exact path={COMPANY_ROUTE} component={Comapny} />
          <AdminPrivateRoute
            exact
            path={CREATE_COMPANY_ROUTE}
            component={CompanyForm}
          />
          <AdminPrivateRoute
            exact
            path={UPDATE_COMPANY_ROUTE}
            component={CompanyForm}
          />
          <AdminPrivateRoute
            exact
            path={COMPANY_DETAIL_ROUTE}
            component={CompanyForm}
          />
          {/* LOCATION ROUTES */}
          <AdminPrivateRoute exact path={LOCATION_ROUTE} component={Location} />
          <AdminPrivateRoute
            exact
            path={CREATE_LOCATION_ROUTE}
            component={LocationForm}
          />
          <AdminPrivateRoute
            exact
            path={UPDATE_LOCATION_ROUTE}
            component={LocationForm}
          />
          <AdminPrivateRoute
            exact
            path={LOCATION_DETAIL_ROUTE}
            component={LocationForm}
          />
          {/* LOOKUP ROUTES */}
          <AdminPrivateRoute exact path={LOOKUP_ROUTE} component={Lookup} />
          <AdminPrivateRoute
            exact
            path={CREATE_LOOKUP_ROUTE}
            component={LookupForm}
          />
          <AdminPrivateRoute
            exact
            path={UPDATE_LOOKUP_ROUTE}
            component={LookupForm}
          />
          <AdminPrivateRoute
            exact
            path={LOOKUP_DETAIL_ROUTE}
            component={LookupForm}
          />
          {/* TRANSACTION ROUTES */}
          <AdminPrivateRoute
            exact
            path={TRANSACTION_ROUTE}
            component={Transactions}
          />

          <AdminPrivateRoute
            exact
            path={TRANSACTION_DETAIL_ROUTE}
            component={TransactionForm}
          />
          {/* REPORT ROUTES */}
          <AdminPrivateRoute exact path={REPORT_ROUTE} component={Reports} />
          <AdminPrivateRoute
            exact
            path={CREATE_REPORT_ROUTE}
            component={ReportsForm}
          />
          <AdminPrivateRoute
            exact
            path={UPDATE_REPORT_ROUTE}
            component={ReportsForm}
          />
          <AdminPrivateRoute
            exact
            path={REPORT_DETAIL_ROUTE}
            component={ReportsForm}
          />
          {/* 404 PAGE ROUTE */}
          <Route path="*" component={ErrorPage} />
        </Switch>
      </Router>
    </>
  );
};
export default Routes;
