import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import { errorToast } from "../utils";
import {
  ERROR_MESSAGE,
  ROLE_DELETE_MESSAGE,
  ROLE_UPDATE_MESSAGE,
  ROLE_CREATE_MESSAGE,
} from "../constants";
import {
  GET_ROLES,
  CREATE_ROLES,
  UPDATE_ROLES,
  DELETE_ROLES,
  GET_SINGLE_ROLES,
  callRequest,
  UPDATE_ROLES_PERMISSION,
} from "../services/webService";

export const RolesContext = createContext();

export const RolesContextProvider = ({ children }) => {
  const [rolesList, setRolesList] = useState([]);
  const [singleRole, setSingleRole] = useState(null);
  const onFailRequest = { status: false, message: ERROR_MESSAGE };
  const getRolesList = async (
    page = 1,
    paginateStatus = true,
    filteredQuery = "",
    responseCallback
  ) => {
    try {
      const query = `page=${page}&paginate=${paginateStatus}${filteredQuery}`;
      const res = await callRequest(GET_ROLES, {}, "", query);
      if (res) {
        setRolesList(res);
      }
      if (responseCallback) responseCallback({ status: true });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const getSingleRole = async (id, responseCallback) => {
    try {
      const res = await callRequest(GET_SINGLE_ROLES, {}, `${id}`, "");
      if (res) {
        setSingleRole(res);
      }
      if (responseCallback) responseCallback({ status: true });
    } catch (err) {
      console.log("err", err);
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const createRole = async (payload, responseCallback) => {
    try {
      const res = await callRequest(CREATE_ROLES, payload, "", "");
      if (res) {
        if (responseCallback) responseCallback(res);
        toast.success(ROLE_CREATE_MESSAGE);
      }
      if (responseCallback)
        responseCallback({ status: false, message: res.message });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const updateRole = async (payload, id, responseCallback) => {
    try {
      const res = await callRequest(UPDATE_ROLES, payload, `${id}`, "");
      if (res) {
        if (responseCallback) responseCallback(res);
        toast.success(ROLE_UPDATE_MESSAGE);
      }
      if (responseCallback)
        responseCallback({ status: false, message: res.message });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };
  const updateRolePermission = async (payload, id, responseCallback) => {
    try {
      let ROUTE = UPDATE_ROLES_PERMISSION;
      ROUTE["route"] = UPDATE_ROLES_PERMISSION.route.replace(":id", id);
      const res = await callRequest(ROUTE, payload, "", "");
      if (res) {
        if (responseCallback) responseCallback(res);
      }
      if (responseCallback)
        responseCallback({ status: false, message: res.message });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const deleteRole = async (id, responseCallback) => {
    try {
      const res = await callRequest(DELETE_ROLES, {}, `${id}`, "");
      if (res) {
        toast.success(ROLE_DELETE_MESSAGE);
        getRolesList();
        if (responseCallback) responseCallback(res);
      }
      if (responseCallback)
        responseCallback({ status: false, message: res.message });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  return (
    <RolesContext.Provider
      value={{
        rolesList,
        singleRole,
        getSingleRole,
        getRolesList,
        createRole,
        updateRole,
        updateRolePermission,
        deleteRole,
      }}
    >
      {children}
    </RolesContext.Provider>
  );
};
