import React, { useContext, useState, useEffect } from "react";
import { Row, Col, Card, Form, Input, Button, Checkbox } from "antd";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RolesContext } from "../../context/RolesContext";
import { GenericContext } from "../../context/genericContext";
import { useHistory, useParams } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Link } from "react-router-dom";
import { ROLES_ROUTE, UPDATE_ROLES_ROUTE, PERMISSIONS } from "../../constants";
import RolePrivilege from "./privilege";
const RoleForm = () => {
  const { id, dataid } = useParams();
  const history = useHistory();
  const paramid = id || dataid;
  const [isLoading, setLoading] = useState(false);
  const [privelegePreview, setprivelegePreview] = useState(false);
  const [rolePriveleges, setrolePriveleges] = useState([]);
  const {
    getSingleRole,
    createRole,
    updateRole,
    updateRolePermission,
    singleRole,
  } = useContext(RolesContext);
  const { getUserPermission, userPermissions, getPriveleges, priveleges } =
    useContext(GenericContext);
  const UpdateAccess = PERMISSIONS.find(
    (x) => x.route === UPDATE_ROLES_ROUTE.replace("/:id", "")
  );
  const PrivelegeAccess = PERMISSIONS.find(
    (x) => x.route === "roles-permission"
  );
  const [form] = Form.useForm();
  const handleSetPrivelege = (id) => {
    let privelege = [...rolePriveleges];
    if (privelege.includes(id)) {
      setrolePriveleges(privelege.filter((x) => x !== id));
    } else {
      setrolePriveleges((rolePriveleges) => [...rolePriveleges, id]);
    }
  };
  const handleFormFinish = () => {
    const credentials = form.getFieldsValue();
    setLoading(true);
    if (id) {
      if (rolePriveleges.length >= 1) {
        const payload = {
          permissions: rolePriveleges.toString(),
        };
        updateRolePermission(payload, id, (response) => {
          if (response.status) {
            updateRole(credentials, id, (res) => {
              setLoading(false);
              if (res.status) {
                history.push(ROLES_ROUTE);
              }
            });
          }
        });
      } else {
        updateRole(credentials, id, (res) => {
          setLoading(false);
          if (res.status) {
            history.push(ROLES_ROUTE);
          }
        });
      }
    } else {
      createRole(credentials, (res) => {
        setLoading(false);
        if (res.status) {
          history.push(ROLES_ROUTE);
        }
      });
    }
  };
  useEffect(() => {
    if (paramid) {
      getSingleRole(paramid);
      getPriveleges();
      getUserPermission();
      if (dataid) {
        setprivelegePreview(true);
      }
    }
  }, []);
  useEffect(() => {
    let oldPriveges = [];
    if (singleRole && paramid) {
      form.setFieldsValue({
        name: singleRole.name,
      });
    }
    if (singleRole?.permissions && singleRole?.permissions.length >= 1) {
      singleRole.permissions.map((x) => {
        oldPriveges.push(x.id);
        return null;
      });
    }
    setrolePriveleges((rolePriveleges) => [...rolePriveleges, ...oldPriveges]);
  }, [singleRole]);

  return (
    <div className="layout-content">
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          {userPermissions.includes(UpdateAccess.access) && (
            <>
              {dataid && (
                <div style={{ textAlign: "right" }}>
                  <Link to={UPDATE_ROLES_ROUTE.replace(":id", paramid)}>
                    <Button className="filter-btn">Edit</Button>
                  </Link>
                </div>
              )}
            </>
          )}
          <Card
            bordered={false}
            className="criclebox cardbody "
            style={{ padding: "20px 40px" }}
          >
            <Form
              form={form}
              className="form"
              onFinish={handleFormFinish}
              disabled={dataid}
            >
              <Row gutter={[24, 0]}>
                <Col xs={24} md={24} lg={24}>
                  <label className="form-lbl">Name:</label>
                  <Form.Item
                    name={"name"}
                    rules={[
                      {
                        required: true,
                        message: "Name can't be empty",
                      },
                    ]}
                  >
                    <Input
                      name="name"
                      disabled={dataid}
                      placeholder=""
                      className="form-input"
                    />
                  </Form.Item>
                </Col>
                {dataid && (
                  <Col xs={24} md={24} lg={24}>
                    <Form.Item name={"activated"}>
                      <Checkbox
                        name="activated"
                        defaultChecked={true}
                        disabled={dataid}
                      />
                      <label className="form-sublbl">Is Default</label>
                    </Form.Item>
                  </Col>
                )}
                {userPermissions.includes(PrivelegeAccess.access) && (
                  <Col xs={24}>
                    <div style={{ textAlign: "right" }}>
                      {id && id !== "1" && (
                        <span
                          className="edit-privelege"
                          onClick={() => {
                            setprivelegePreview(!privelegePreview);
                          }}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                          Edit Priveleges
                        </span>
                      )}
                    </div>
                    {privelegePreview && (
                      <>
                        <h5 className="form-mttl">PRIVILEGES</h5>
                        <RolePrivilege
                          priveleges={priveleges}
                          rolePriveleges={rolePriveleges}
                          setRolePrivelege={handleSetPrivelege}
                        />
                      </>
                    )}
                  </Col>
                )}
                {!dataid && (
                  <Col xs={24} md={24} lg={24}>
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        disabled={isLoading}
                        className="form-btn"
                      >
                        {isLoading ? (
                          <ScaleLoader
                            color={"#FFF"}
                            loading={isLoading}
                            height={14}
                            width={2}
                          />
                        ) : id ? (
                          "Update"
                        ) : (
                          "Create"
                        )}
                      </Button>
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default RoleForm;
