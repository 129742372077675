import React from "react";
import "./App.scss";
import PageRoutes from "./routes/index";
import "antd/dist/antd.css";
import "suneditor/dist/css/suneditor.min.css";

function App() {
  return <PageRoutes />;
}

export default App;
