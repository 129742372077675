import React, { useContext, useState, useEffect } from "react";
import { AgreementContext } from "../../context/agreementContext";
import { useParams } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
const AgencyContentViewer = () => {
  const { id } = useParams();
  const [loading, setloading] = useState(true);
  const [html, setHTML] = useState({ __html: "" });
  const { singleAgreement, getSingleAgreement } = useContext(AgreementContext);
  useEffect(() => {
    if (id) {
      getSingleAgreement(id, (res) => {
        if (res) {
          setloading(false);
        }
      });
    }
  }, []);
  useEffect(() => {
    if (singleAgreement?.agency_content) {
      setHTML({ __html: singleAgreement?.agency_content });
    }
  }, [singleAgreement]);
  if (loading)
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <ScaleLoader />
      </div>
    );
  return (
    <>
      {singleAgreement?.agency_content ? <div dangerouslySetInnerHTML={html} /> : null}
    </>
  );
};

export default AgencyContentViewer;
