import axios from "axios";
import { toast } from "react-toastify";
import {
  ERROR_TOKEN_EXPIRE,
  ERROR_ACCOUNT_BLOCKED,
  ERROR_SOMETHING_WENT_WRONG,
  ERROR_NETWORK_NOT_AVAILABLE,
} from "./webService";
import { LOGIN_ROUTE } from "../constants";
import { message } from "antd";

const manipulateResponse = (response) => {
  return new Promise((resolve, reject) => {
    if (response.data && !response.data.error) {
      if (response.message?.errors) {
        reject(response.message.errors);
      } else {
        resolve(response.data);
      }
    } else {
      reject(response.data || ERROR_SOMETHING_WENT_WRONG);
    }
  });
};
const ApiHandler = async (request, url, data, headers) => {
  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_BACKEND_ENV,
      method: request,
      url: url,
      data: data,
      headers: headers,
    });
    return manipulateResponse(response);
  } catch ({ response }) {
    if (response.status === 401) {
      toast.error(ERROR_TOKEN_EXPIRE);
      window.location.replace(LOGIN_ROUTE);
      localStorage.removeItem("burque_token");
      return false;
    }
    if (response.status === 403) {
      toast.error(ERROR_TOKEN_EXPIRE);
      // toast.error(ERROR_ACCOUNT_BLOCKED);
      window.location.replace(LOGIN_ROUTE);
      localStorage.removeItem("burque_token");
      return false;
    }
    if (response.status === 500) {
      toast.error(ERROR_SOMETHING_WENT_WRONG);
      return false;
    }
    if (response.problem === "NETWORK_ERROR") {
      toast.error(ERROR_NETWORK_NOT_AVAILABLE);
      return false;
    }
    if (typeof response.data.message === "string") {
      toast.error(response.data.message);
      return false;
    }
    if (response.data.message.errors) {
      toast.error(response.data.message.errors[0].message);
      return false;
    }
    return { status: false, message: response.data.message };
  }
};

export default ApiHandler;
