import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Card, Form, Input, Button, Select } from "antd";
import { LookupsContext } from "../../context/LookupContext";
import { GenericContext } from "../../context/genericContext";
import ScaleLoader from "react-spinners/ScaleLoader";
import {
  LOOKUP_ROUTE,
  LOOKUP_TYPES,
  UPDATE_LOOKUP_ROUTE,
  PERMISSIONS,
} from "../../constants";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
const LookupForm = () => {
  const { id, dataid } = useParams();
  const history = useHistory();
  const paramid = id || dataid;
  const [isLoading, setLoading] = useState(false);
  const { singleLookup, getSingleLookup, createLookup, updateLookup } =
    useContext(LookupsContext);
  const { getUserPermission, userPermissions } = useContext(GenericContext);
  const UpdateAccess = PERMISSIONS.find(
    (x) => x.route === UPDATE_LOOKUP_ROUTE.replace("/:id", "")
  );
  const [form] = Form.useForm();
  const handleFormFinish = () => {
    const credentials = form.getFieldsValue();
    setLoading(true);
    if (id) {
      updateLookup(credentials, id, (res) => {
        setLoading(false);
        if (res.status) {
          history.push(LOOKUP_ROUTE);
        }
      });
    } else {
      createLookup(credentials, (res) => {
        setLoading(false);
        if (res.status) {
          history.push(LOOKUP_ROUTE);
        }
      });
    }
  };
  useEffect(() => {
    if (paramid) {
      getSingleLookup(paramid);
    }
    getUserPermission();
  }, []);
  useEffect(() => {
    if (singleLookup && paramid) {
      form.setFieldsValue({
        type: singleLookup.type,
        name: singleLookup.name,
        code: singleLookup.code,
        lookup_value: singleLookup.lookup_value,
      });
    }
  }, [singleLookup]);

  return (
    <div className="layout-content">
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          {userPermissions.includes(UpdateAccess.access) && (
            <>
              {dataid && (
                <div style={{ textAlign: "right" }}>
                  <Link to={UPDATE_LOOKUP_ROUTE.replace(":id", paramid)}>
                    <Button className="filter-btn">Edit</Button>
                  </Link>
                </div>
              )}
            </>
          )}
          <Card
            bordered={false}
            className="criclebox cardbody "
            style={{ padding: "20px 40px" }}
          >
            <Form form={form} className="form" onFinish={handleFormFinish}>
              <Row gutter={[24, 0]}>
                <Col xs={24} md={12} lg={8}>
                  <label className="form-lbl">Type:</label>
                  <Form.Item
                    name={"type"}
                    rules={[
                      {
                        required: true,
                        message: "please select lookup type",
                      },
                    ]}
                  >
                    <Select
                      placeholder=""
                      name="type"
                      disabled={dataid}
                      className="form-select"
                      // showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {LOOKUP_TYPES?.map((data, index) => (
                        <Select.Option value={data} key={index}>
                          {data}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <label className="form-lbl">Name:</label>
                  <Form.Item
                    name={"name"}
                    rules={[
                      {
                        required: true,
                        message: "Name can't be empty",
                      },
                    ]}
                  >
                    <Input
                      name="name"
                      disabled={dataid}
                      placeholder=""
                      className="form-input"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <label className="form-lbl">Code:</label>
                  <Form.Item
                    name={"code"}
                    rules={[
                      {
                        required: true,
                        message: "Code can't be empty",
                      },
                    ]}
                  >
                    <Input
                      name="code"
                      disabled={dataid}
                      placeholder=""
                      className="form-input"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <label className="form-lbl">Value:</label>
                  <Form.Item
                    name={"lookup_value"}
                    rules={[
                      {
                        required: true,
                        message: "Lookup value can't be empty",
                      },
                    ]}
                  >
                    <Input
                      disabled={dataid}
                      name="lookup_value"
                      placeholder=""
                      className="form-input"
                    />
                  </Form.Item>
                </Col>
                {!dataid && (
                  <Col xs={24} md={24} lg={24}>
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        disabled={isLoading}
                        className="form-btn"
                      >
                        {isLoading ? (
                          <ScaleLoader
                            color={"#FFF"}
                            loading={isLoading}
                            height={14}
                            width={2}
                          />
                        ) : id ? (
                          "Update"
                        ) : (
                          "Create"
                        )}
                      </Button>
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default LookupForm;
