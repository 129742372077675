import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { errorToast } from "../utils";
import {
  ERROR_MESSAGE,
  LOGIN_MESSAGE,
  LOGIN_ROUTE,
  LOGOUT_MESSAGE,
} from "../constants";
import { callRequest, USER_LOGIN, USER_LOGOUT } from "../services/webService";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState(null);
  const onFailRequest = { status: false, message: ERROR_MESSAGE };
  const handleAdminLogin = async (payload, responseCallback) => {
    try {
      const res = await callRequest(USER_LOGIN, payload, "", "");
      if (res.status) {
        toast.success(LOGIN_MESSAGE);
        setCurrentUser(res.data.user);
        localStorage.setItem(
          "burque_acess",
          JSON.stringify(res?.data?.role?.permissions)
        );
        localStorage.setItem("burque_token", res?.data?.token.token);
        if (responseCallback) responseCallback({ status: true });
      }
      if (responseCallback) responseCallback({ status: false });
    } catch (err) {
      console.log({ err });
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };
  const handleAdminLogout = async (responseCallback) => {
    try {
      const res = await callRequest(USER_LOGOUT, {}, "", "");
      localStorage.removeItem("burque_token");
      toast.error(LOGOUT_MESSAGE);
      if (responseCallback) responseCallback({ status: true });
    } catch (err) {
      console.log({ err });
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        adminLogin: handleAdminLogin,
        adminLogout: handleAdminLogout,
        currentUser: currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
