import React, { useEffect, useState, useContext } from "react";
import {
  Col,
  Row,
  Tooltip,
  Select,
  DatePicker,
  Input,
  Button,
  Form,
} from "antd";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import AdminDataTable from "../../Layouts/partials/data-table";
import { AgreementContext } from "../../context/agreementContext";
import { PrincipalsContext } from "../../context/PrincipalContext";
import { UsersContext } from "../../context/UsersContext";
import { CompanyContext } from "../../context/CompanyContext";
import { GenericContext } from "../../context/genericContext";
import { ConfirmationModal } from "../../components/Modals/index";
import { Link } from "react-router-dom";
import {
  CREATE_AGREEMENT_ROUTE,
  UPDATE_AGREEMENT_ROUTE,
  AGREEMENT_DETAIL_ROUTE,
  PERMISSIONS,
} from "../../constants";
import { getFormattedDateTime, getFormattedDate, formatCurrency } from "../../utils";
import FilterDrawer from "../../components/filterDrawer";
import { ScaleLoader } from "react-spinners";
import moment from "moment";

function Agreements() {
  const { RangePicker } = DatePicker;
  const { agreementsList, getAgreementsList, deleteAgreement } =
    useContext(AgreementContext);
  const { principalsList, getPrincipalsList } = useContext(PrincipalsContext);
  const { companyList, getCompanyList } = useContext(CompanyContext);
  const { usersList, getUsersList } = useContext(UsersContext);
  const {
    agreementStatus,
    getAgreementStatusList,
    getUserPermission,
    userPermissions,
  } = useContext(GenericContext);
  const CreateAccess = PERMISSIONS.find(
    (x) => x.route === CREATE_AGREEMENT_ROUTE
  );
  const UpdateAccess = PERMISSIONS.find(
    (x) => x.route === UPDATE_AGREEMENT_ROUTE.replace("/:id", "")
  );
  const [selectedData, setSelectedData] = useState(null);
  const [principalData, setPrincipalData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [agreementStatusData, setAgreementStatusData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState({ agreement_status_id: 2 });
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [page, setPage] = useState(1);
  const { meta, data } = agreementsList;
  const paginationConfig = {
    pageSize: meta?.per_page,
    total: meta?.total,
  };

  const [form] = Form.useForm();
  const handleFormFinish = () => {
    const credentials = form.getFieldsValue();
    if (Array.isArray(credentials.date)) {
      credentials["start_date"] = getFormattedDate(credentials.date[0]);
      credentials["end_date"] = getFormattedDate(credentials.date[1]);
    }
    delete credentials["date"];
    setFilteredData(credentials);
    setLoading(true);
  };

  useEffect(() => {
    let filterQuery = "";
    if (filteredData) {
      Object.keys(filteredData).forEach(function (key) {
        if (filteredData[key]) {
          filterQuery += `&${key}=${filteredData[key]}`;
        }
      });
    }
    getUserPermission();
    getAgreementsList(page, true, filterQuery, () => {
      setLoading(false);
      setFilterOpen(false);
    });
  }, [page, filteredData]);

  useEffect(() => {
    getPrincipalsList(1, false, "");
    getCompanyList(1, false, "");
    getUsersList(1, false, "");
    getAgreementStatusList();
  }, []);

  useEffect(() => {
    setPrincipalData(
      Array.isArray(principalsList) ? principalsList : principalsList.data
    );
  }, [principalsList]);

  useEffect(() => {
    setCompanyData(Array.isArray(companyList) ? companyList : companyList.data);
  }, [companyList]);

  useEffect(() => {
    setUsersData(
      Array.isArray(usersList)
        ? usersList.filter((x) => x.role_id === 3)
        : usersList.data.filter((x) => x.role_id === 3)
    );
  }, [usersList]);
  useEffect(() => {
    setAgreementStatusData(
      Array.isArray(agreementStatus) ? agreementStatus : agreementStatus.data
    );
  }, [agreementStatus]);

  const handleResetForm = () => {
    form.resetFields();
    setFilteredData(null);
    form.setFieldsValue({
      code: undefined,
      agreement_status_id: undefined,
      date: undefined,
      company_id: undefined,
      principal_id: undefined,
      user_id: undefined,
    });
    setFilterOpen(false);
  };
  const drawerSec = () => (
    <Form
      form={form}
      className="form"
      initialValues={filteredData}
      onFinish={handleFormFinish}
    >
      <label className="form-lbl">Code:</label>
      <Form.Item name={"code"}>
        <Input
          name="name"
          placeholder="Filter by agreement code"
          className="form-input"
        />
      </Form.Item>
      <label className="form-lbl">Status:</label>
      <Form.Item name={"agreement_status_id"}>
        <Select
          placeholder="Filter by agreement status"
          name="agreement_status_id"
          className="form-select"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {agreementStatusData.map((data, index) => (
            <Select.Option value={data.id} key={index}>
              {data.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <label className="form-lbl">Date:</label>
      <Form.Item name={"date"}>
        <RangePicker className="form-input" />
      </Form.Item>
      <label className="form-lbl">Company:</label>
      <Form.Item name={"company_id"}>
        <Select
          placeholder="Filter by company"
          name="company_id"
          className="form-select"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {companyData.map((data, index) => (
            <Select.Option value={data.id} key={index}>
              {data.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <label className="form-lbl">Principal:</label>
      <Form.Item name={"principal_id"}>
        <Select
          placeholder="Filter by principal"
          name="principal_id"
          className="form-select"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {principalData.map((data, index) => (
            <Select.Option value={data.id} key={index}>
              {data.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <label className="form-lbl">Financer:</label>
      <Form.Item name={"user_id"}>
        <Select
          placeholder="Filter by financer"
          name="user_id"
          className="form-select"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {usersData.map((data, index) => (
            <Select.Option value={data.id} key={index}>
              {data.first_name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Button
          htmlType="submit"
          disabled={isLoading}
          className="form-btn"
          style={{ minWidth: "100%" }}
        >
          {isLoading ? (
            <ScaleLoader
              color={"#FFF"}
              loading={isLoading}
              height={14}
              width={2}
            />
          ) : (
            "Apply Filters"
          )}
        </Button>
        <span className="form-rm-btn" onClick={handleResetForm}>
          Reset Filters
        </span>
      </Form.Item>
    </Form>
  );

  const columns = [
    {
      title: "Sequence Number",
      dataIndex: "sequence_number",
      key: "1",
      sorter: (a, b) => a.sequence_number - b.sequence_number,
      render: (data, value) => {
        return <span>{data}</span>;
      },
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "2",
      sorter: (a, b) => a.code.localeCompare(b.code),
      render: (data, value) => {
        return <span>{data}</span>;
      },
    },
    {
      title: "Companies",
      dataIndex: "companies",
      key: "3",
      sorter: (a, b) =>
        a.companies[0]?.name.localeCompare(b.companies[0]?.name),
      render: (data, value) => {
        let companies = "";
        data.map((item, index) => {
          companies += index === 0 ? item.name : `, ${item.name}`;
          return null;
        });
        return <span>{companies}</span>;
      },
    },
    {
      title: "Financier",
      dataIndex: "financier",
      key: "4",
      sorter: (a, b) => a.financier.first_name.localeCompare(b.financier.first_name),
      render: (data, value) => {
        return <span>{data.first_name} {data.last_name}</span>;
      },
    },
    {
      title: "Starting Date",
      dataIndex: "start_date",
      key: "5",
      sorter: (a, b) =>
        moment(a.start_date).unix() - moment(b.start_date).unix(),
      render: (data, value) => {
        return <span>{getFormattedDateTime(data)}</span>;
      },
    },
    {
      title: "Ending Date",
      dataIndex: "end_date",
      key: "6",
      sorter: (a, b) => moment(a.end_date).unix() - moment(b.end_date).unix(),
      render: (data, value) => {
        return <span>{getFormattedDateTime(data)}</span>;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "7",
      sorter: (a, b) => a.amount - b.amount,
      render: (data, value) => {
        return <span>{formatCurrency(data)}</span>;
      },
    },
    {
      title: "Unutilized Balance",
      dataIndex: "agreement_limit",
      key: "8",
      sorter: (a, b) => a.agreement_limit - b.agreement_limit,
      render: (data, value) => {
        return <span>{formatCurrency(data.toFixed(2))}</span>;
      },
    },
    {
      title: "Utilized %",
      key: "9",
      sorter: (a, b) => (100 - (a.agreement_limit / a.amount) * 100) - (100 - (a.agreement_limit / a.amount) * 100),
      render: (data, value) => {
        return <span>{parseFloat(100 - (data.agreement_limit / data.amount) * 100).toFixed(2)}</span>;
      },
    },
    {
      title: "Agreement Status",
      dataIndex: "agreement_status",
      key: "10",
      sorter: (a, b) =>
        a.agreement_status?.name.localeCompare(b.agreement_status?.name),
      render: (data, value) => {
        return <span>{data.name}</span>;
      },
    },
    {
      title: "Principals",
      dataIndex: "principals",
      key: "11",
      sorter: (a, b) =>
        a.principals[0]?.name.localeCompare(b.principals[0]?.name),
      render: (data, value) => {
        let principals = "";
        data.map((item, index) => {
          principals += index === 0 ? item.name : `, ${item.name}`;
          return null;
        });
        return <span>{principals}</span>;
      },
    },
    {
      title: "Created By",
      dataIndex: "creator",
      key: "12",
      sorter: (a, b) => a.creator?.email.localeCompare(a.creator?.email),
      render: (data, value) => {
        return <span>{data.email}</span>;
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "13",
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
      render: (data, value) => {
        return <span>{getFormattedDateTime(data)}</span>;
      },
    },
    {
      title: "Action",
      key: "14",
      render: (data, record) => {
        return (
          <div className="actions">
            <Link
              to={AGREEMENT_DETAIL_ROUTE.replace(":dataid", data.id)}
              className="update"
            >
              <Tooltip title="Agreement Detail">
                <EyeOutlined />
              </Tooltip>
            </Link>

            {userPermissions.includes(UpdateAccess.access) && (
              <Link
                to={UPDATE_AGREEMENT_ROUTE.replace(":id", data.id)}
                className="update"
              >
                <Tooltip title="Update Agreement">
                  <EditOutlined />
                </Tooltip>
              </Link>
            )}
            {/* <span style={{ opacity: data.is_default ? 0.3 : 1 }}>
              {data.is_default ? (
                <DeleteOutlined style={{ cursor: "auto" }} />
              ) : (
                <Tooltip title="Delete Agreement">
                  <DeleteOutlined onClick={() => handleConfirmModal(record)} />
                </Tooltip>
              )}
            </span> */}
          </div>
        );
      },
    },
  ];

  //HANDLERS

  const handleConfirmModal = (role) => {
    setSelectedData(role);
    setShowConfirmationModal(true);
  };
  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleDeleteRole = (id) => {
    deleteAgreement(id);
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };

  const filterHandler = () => {
    setFilterOpen(!isFilterOpen);
  };

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            {userPermissions.includes(CreateAccess.access) && (
              <div className="add-btn">
                <Link to={CREATE_AGREEMENT_ROUTE}>Create Agreement</Link>
              </div>
            )}
            <AdminDataTable
              title="Agreements"
              filterHandler={filterHandler}
              data={data}
              columns={columns}
              pagination={{ ...paginationConfig, onChange: handlePageChange }}
            />
          </Col>
        </Row>
      </div>
      {showConfirmationModal && (
        <ConfirmationModal
          handleClose={handleConfirmationModalClose}
          title={"Delete Agreement?"}
          description={"Are you sure you want to delete this agreement?"}
          deleteHandler={handleDeleteRole}
          selectedData={selectedData}
        />
      )}
      <FilterDrawer
        visible={isFilterOpen}
        toggle={filterHandler}
        content={drawerSec()}
      />
    </>
  );
}

export default Agreements;
