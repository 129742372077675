import React, { useEffect, useState, useContext } from "react";
import {
  Col,
  Row,
  Select,
  Tooltip,
  Button,
  Form,
  Input,
  DatePicker,
} from "antd";
import { EyeOutlined, CloseCircleOutlined } from "@ant-design/icons";
import AdminDataTable from "../../Layouts/partials/data-table";
import { TransactionsContext } from "../../context/transactionContext";
import { PrincipalsContext } from "../../context/PrincipalContext";
import { LocationsContext } from "../../context/LocationContext";
import { Link } from "react-router-dom";
import { TRANSACTION_DETAIL_ROUTE, TRANSACTION_STATUS } from "../../constants";
import {
  getFormattedDate,
  getFormattedDateTime,
  formatCurrency,
} from "../../utils";
import { ConfirmationModal } from "../../components/Modals/index";
import FilterDrawer from "../../components/filterDrawer";
import { ScaleLoader } from "react-spinners";
import moment from "moment";
function Transactions() {
  const { RangePicker } = DatePicker;
  const [filteredData, setFilteredData] = useState(null);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const { transactionsList, getTransactionsList, cancelTransaction } =
    useContext(TransactionsContext);
  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [principalData, setPrincipalData] = useState([]);
  const [locationData, setlocationData] = useState([]);
  const { principalsList, getPrincipalsList } = useContext(PrincipalsContext);
  const { locationsList, getLocationsList } = useContext(LocationsContext);
  const { meta, data } = transactionsList;
  const paginationConfig = {
    pageSize: meta?.per_page,
    total: meta?.total,
  };
  const [form] = Form.useForm();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const handleFormFinish = () => {
    const credentials = form.getFieldsValue();
    if (Array.isArray(credentials.date)) {
      credentials["start_date"] = getFormattedDate(credentials.date[0]);
      credentials["end_date"] = getFormattedDate(credentials.date[1]);
    }
    delete credentials["date"];
    setFilteredData(credentials);
    setLoading(true);
  };
  useEffect(() => {
    let filterQuery = "";
    if (filteredData) {
      Object.keys(filteredData).forEach(function (key) {
        if (filteredData[key]) {
          filterQuery += `&${key}=${filteredData[key]}`;
        }
      });
    }
    getTransactionsList(page, true, filterQuery, () => {
      setLoading(false);
      setFilterOpen(false);
    });
  }, [page, filteredData]);

  useEffect(() => {
    getPrincipalsList(1, false, "");
    getLocationsList(1, false, "");
  }, []);

  useEffect(() => {
    setPrincipalData(
      Array.isArray(principalsList) ? principalsList : principalsList.data
    );
  }, [principalsList]);

  useEffect(() => {
    setlocationData(
      Array.isArray(locationsList) ? locationsList : locationsList.data
    );
  }, [locationsList]);

  const handleResetForm = () => {
    form.resetFields();
    setFilteredData(null);
    form.setFieldsValue({
      status: undefined,
      invoice_code: undefined,
      dc_code: undefined,
      date: undefined,
      agreement_code: undefined,
      warehouse_user: undefined,
      financier: undefined,
      location: undefined,
      principal: undefined,
    });
    setFilterOpen(false);
  };
  const drawerSec = () => (
    <Form
      form={form}
      className="form"
      initialValues={filteredData}
      onFinish={handleFormFinish}
    >
      <label className="form-lbl">Status:</label>
      <Form.Item name={"status"}>
        <Select
          placeholder="Filter by Status"
          name="status"
          className="form-select"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {TRANSACTION_STATUS.map((data, index) => (
            <Select.Option value={data.id} key={index}>
              {data.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <label className="form-lbl">Invoice No:</label>
      <Form.Item name={"invoice_code"}>
        <Input
          type="number"
          name="invoice_code"
          placeholder="Filter by invoice no"
          className="form-input"
        />
      </Form.Item>
      <label className="form-lbl">Dc No:</label>
      <Form.Item name={"dc_code"}>
        <Input
          type="number"
          name="dc_code"
          placeholder="Filter by dc no"
          className="form-input"
        />
      </Form.Item>
      <label className="form-lbl">Select Date Range:</label>
      <Form.Item name={"date"}>
        <RangePicker className="form-input" />
      </Form.Item>
      <label className="form-lbl">Agreement Code:</label>
      <Form.Item name={"agreement_code"}>
        <Input
          name="agreement_code"
          placeholder="Filter by agreement code"
          className="form-input"
        />
      </Form.Item>
      <label className="form-lbl">Warehouse User:</label>
      <Form.Item name={"warehouse_user"}>
        <Input
          name="warehouse_user"
          placeholder="Filter by warehouse username"
          className="form-input"
        />
      </Form.Item>
      <label className="form-lbl">Financer Name:</label>
      <Form.Item name={"financier"}>
        <Input
          name="financier"
          placeholder="Filter by financer name"
          className="form-input"
        />
      </Form.Item>
      <label className="form-lbl">Principal:</label>
      <Form.Item name={"principal_id"}>
        <Select
          placeholder="Filter by principal"
          name="principal_id"
          className="form-select"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {principalData.map((data, index) => (
            <Select.Option value={data.id} key={index}>
              {data.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <label className="form-lbl">Location:</label>
      <Form.Item name={"location_id"}>
        <Select
          placeholder="Filter by location"
          name="location_id"
          className="form-select"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {locationData.map((data, index) => (
            <Select.Option value={data.id} key={index}>
              {data.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Button
          htmlType="submit"
          disabled={isLoading}
          className="form-btn"
          style={{ minWidth: "100%" }}
        >
          {isLoading ? (
            <ScaleLoader
              color={"#FFF"}
              loading={isLoading}
              height={14}
              width={2}
            />
          ) : (
            "Apply Filters"
          )}
        </Button>
        <span className="form-rm-btn" onClick={handleResetForm}>
          Reset Filters
        </span>
      </Form.Item>
    </Form>
  );
  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "1",
      render: (data, value, i) => (
        <b>{(page == 1 ? 0 : (page - 1) * 10) + (i + 1)}</b>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "2",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (data, value) => {
        return <span>{data}</span>;
      },
    },
    {
      title: "Murabaha Officer",
      dataIndex: "warehouse_user",
      key: "10",
      sorter: (a, b) =>
        a.warehouse_user?.first_name.localeCompare(
          b.warehouse_user?.first_name
        ),
      render: (data, value) => {
        return (
          <span>
            {data?.first_name} {data?.last_name}
          </span>
        );
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "7",
      sorter: (a, b) => a.location?.code.localeCompare(b.location?.code),
      render: (data, value) => {
        return <span>{data?.name || "-"}</span>;
      },
    },
    {
      title: "Company",
      dataIndex: "agreement",
      key: "9",
      sorter: (a, b) =>
        a.agrement?.companies[0].localeCompare(b.agrement?.companies[0]),
      render: (data, value) => {
        let companies = "";
        data.companies.map((item, index) => {
          companies += index === 0 ? item.name : `, ${item.name}`;
          return null;
        });
        return <span>{companies}</span>;
      },
    },
    {
      title: "Principal",
      dataIndex: "principal",
      key: "8",
      sorter: (a, b) => a.principal?.name.localeCompare(b.principal?.name),
      render: (data, value) => {
        return <span>{data?.name || "-"}</span>;
      },
    },
    {
      title: "Transection Date",
      dataIndex: "created_at",
      key: "4",
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
      render: (data, value) => {
        return <span>{getFormattedDateTime(data)}</span>;
      },
    },
    {
      title: "Financer Name",
      dataIndex: "agreement",
      key: "11",
      sorter: (a, b) =>
        a.agreement?.financier?.first_name.localeCompare(
          b.agreement?.financier?.first_name
        ),
      render: (data, value) => {
        return (
          <span>
            {data?.financier?.first_name} {data?.financier?.last_name}
          </span>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "",
      key: "12",
      sorter: (a, b) => a.total_invoice_amount - b.total_invoice_amount,
      render: (data, value) => {
        return (
          <span>{formatCurrency(data?.total_invoice_amount.toFixed(2))}</span>
        );
      },
    },
    {
      title: "Invoice / DC No(s)",
      dataIndex: "",
      key: "5",
      sorter: (a, b) => a.invoices[0]?.code - b.invoices[0]?.code,
      render: (data, value) => {
        let invoices_dc = "";
        if (data?.invoices.length >= 1) {
          data.invoices.map((item, index) => {
            invoices_dc += index === 0 ? item.code : `, ${item.code}`;
            return null;
          });
        } else if (data?.dcs?.length >= 1) {
          data?.dcs.map((item, index) => {
            invoices_dc += index === 0 ? item.code : `, ${item.code}`;
            return null;
          });
        } else {
          invoices_dc = "NA";
        }
        return <span>{invoices_dc}</span>;
      },
    },
    {
      title: "Agreement Code",
      dataIndex: "agreement",
      key: "6",
      sorter: (a, b) => a.agrement?.code.localeCompare(b.agrement?.code),
      render: (data, value) => {
        return <span>{data?.code || "-"}</span>;
      },
    },
    {
      title: "Action",
      key: "13",
      render: (data, record) => {
        return (
          <div className="actions">
            <Link
              to={TRANSACTION_DETAIL_ROUTE.replace(":dataid", data.id)}
              className="update"
            >
              <Tooltip title="Transaction Detail">
                <EyeOutlined />
              </Tooltip>
            </Link>
            {(data.status == "hold" || data.status == "in progress") && (
              <Tooltip title="Cancel Transaction">
                <CloseCircleOutlined onClick={() => handleCancelModal(data)} />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  //HANDLERS
  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };

  const filterHandler = () => {
    setFilterOpen(!isFilterOpen);
  };

  const handleCancelModal = (transaction) => {
    setSelectedData(transaction);
    setShowCancelModal(true);
  };

  const handleCancelTransaction = () => {
    cancelTransaction(selectedData.id);
  };

  const handleCancelModalClose = () => {
    setShowCancelModal(false);
  };

  const showInvoice_Dc = (dcs) => {
    return dcs.length > 0 ? "dcs" : "invoices";
  };

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <AdminDataTable
              title="Transactions"
              filterHandler={filterHandler}
              data={data}
              columns={columns}
              pagination={{ ...paginationConfig, onChange: handlePageChange }}
            />
          </Col>
        </Row>
      </div>
      {showCancelModal && (
        <ConfirmationModal
          handleClose={handleCancelModalClose}
          title={"Cancel Transaction?"}
          description={"Are you sure you want to cancel this transaction?"}
          deleteHandler={handleCancelTransaction}
          selectedData={selectedData}
        />
      )}
      <FilterDrawer
        visible={isFilterOpen}
        toggle={filterHandler}
        content={drawerSec()}
      />
    </>
  );
}

export default Transactions;
