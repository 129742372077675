import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import { errorToast } from "../utils";
import {
  ERROR_MESSAGE,
  USER_DELETE_MESSAGE,
  USER_UPDATE_MESSAGE,
  USER_CREATE_MESSAGE,
} from "../constants";
import {
  GET_USERS,
  CREATE_USERS,
  UPDATE_USERS,
  DELETE_USERS,
  GET_SINGLE_USERS,
  callRequest,
} from "../services/webService";

export const UsersContext = createContext();

export const UsersContextProvider = ({ children }) => {
  const [usersList, setUsersList] = useState([]);
  const [singleUser, setSingleUser] = useState(null);
  const onFailRequest = { status: false, message: ERROR_MESSAGE };
  const getUsersList = async (
    page = 1,
    paginateStatus = true,
    filteredQuery = "",
    responseCallback
  ) => {
    try {
      const query = `page=${page}&paginate=${paginateStatus}${filteredQuery}`;
      const res = await callRequest(GET_USERS, {}, "", query);
      if (res) {
        setUsersList(res);
      }
      if (responseCallback) responseCallback({ status: true });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const getSingleUser = async (id, responseCallback) => {
    try {
      const res = await callRequest(GET_SINGLE_USERS, {}, `${id}`, "");
      if (res) {
        setSingleUser(res);
      }
      if (responseCallback) responseCallback({ status: true });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const createUser = async (payload, responseCallback) => {
    try {
      const res = await callRequest(CREATE_USERS, payload, "", "");
      if (res) {
        if (responseCallback) responseCallback(res);
        toast.success(USER_CREATE_MESSAGE);
      }
      if (responseCallback)
        responseCallback({ status: false, message: res.message });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const updateUser = async (payload, id, responseCallback) => {
    try {
      const res = await callRequest(UPDATE_USERS, payload, `${id}`, "");
      if (res) {
        if (responseCallback) responseCallback(res);
        toast.success(USER_UPDATE_MESSAGE);
      }
      if (responseCallback)
        responseCallback({ status: false, message: res.message });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  const deleteUser = async (id, responseCallback) => {
    try {
      const res = await callRequest(DELETE_USERS, {}, `${id}`, "");
      if (res) {
        toast.success(USER_DELETE_MESSAGE);
        getUsersList();
        if (responseCallback) responseCallback(res);
      }
      if (responseCallback)
        responseCallback({ status: false, message: res.message });
    } catch (err) {
      if (responseCallback) responseCallback(onFailRequest);
      errorToast(onFailRequest);
    }
  };

  return (
    <UsersContext.Provider
      value={{
        usersList,
        singleUser,
        getSingleUser,
        getUsersList,
        createUser,
        updateUser,
        deleteUser,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};
